import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router";
import CheckBack from "./components/CheckBack";

const Pending = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    document.title = "Redirigiendo";
  }, []);

  useEffect(() => {
    let timer = setTimeout(() => {
      if (location.state?.back) {
        navigate("/", { replace: true });
      }
    }, 5000);

    return () => clearTimeout(timer);
  });

  return (
    <>
      <CheckBack>
        <div className="text-center">
          <div style={{ height: "15vh" }}></div>
          <div className="fw-bold" style={{ fontSize: "32pt" }}>
            Tu solicitud ha sido enviada
          </div>
          <div style={{ fontSize: "30pt" }}>Recibirás un correo pronto!</div>
          <div className="mb-5" />
          <div style={{ fontSize: "18pt" }}>
            Serás redirigido a la página principal en 5 segundos ...
          </div>
        </div>
      </CheckBack>
    </>
  );
};

export default Pending;
