import Button from "react-bootstrap/Button";

import React, { useImperativeHandle, useState } from "react";
import ModalBS from "react-bootstrap/Modal";
import Loading from "./Loading";

const Modal = React.forwardRef(
  (
    {
      title = "Title",
      onAccept = async () => {},
      onCancel = async () => {},
      onHide = () => {},
      outsideClose = false,
      loadOnAccept = false,
      hideAccept,
      hideCancel,
      acceptText = "Aceptar",
      cancelText = "Cancelar",
      children,
      size,
      ...props
    },
    ref
  ) => {
    useImperativeHandle(ref, () => ({
      show,
    }));

    const [visible, setVisible] = useState(false);

    const [loading, setLoading] = useState(false);

    const show = () => {
      setLoading(false);
      setVisible(true);
    };

    const hide = () => {
      setVisible(false);
      onHide();
    };

    const handleAccept = async () => {
      if (loadOnAccept) {
        setLoading(true);
      }
      try {
        await onAccept();
        hide();
      } catch (err) {
        if (loadOnAccept) {
          setLoading(false);
        }
        if (process.env.NODE_ENV === "development") {
          console.log(err);
        }
      }
    };

    const handleCancel = async () => {
      try {
        await onCancel();
        hide();
      } catch (err) {
        if (process.env.NODE_ENV === "development") {
          console.log(err);
        }
      }
    };

    return (
      <ModalBS
        show={visible}
        onHide={handleCancel}
        {...(outsideClose ? {} : { backdrop: "static" })}
        size={size}
        {...props}
      >
        <ModalBS.Header closeButton={!loading}>
          <ModalBS.Title>{title}</ModalBS.Title>
        </ModalBS.Header>

        <ModalBS.Body> {loading ? <Loading /> : children}</ModalBS.Body>
        {(!hideAccept || !hideCancel) && (
          <ModalBS.Footer className="justify-content-center">
            {!hideCancel ? (
              <Button
                variant="secondary"
                disabled={loading}
                onClick={handleCancel}
              >
                {cancelText}
              </Button>
            ) : (
              <></>
            )}
            {!hideAccept ? (
              <Button
                variant="primary"
                disabled={loading}
                onClick={handleAccept}
              >
                {acceptText}
              </Button>
            ) : (
              <></>
            )}
          </ModalBS.Footer>
        )}
      </ModalBS>
    );
  }
);

export default Modal;
