import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import AuthContext from "../AuthContext";
import { intersect } from "../utils";

// TODO to is going to 404
const CheckBack = ({
  allowedRoles = [],
  to = "/",
  onShow = () => {},
  disabled,
  children,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [show, setShow] = useState(false);

  const authContext = useContext(AuthContext);

  useEffect(() => {
    if (disabled) {
      return;
    }
    if (
      !intersect(authContext.user?.roles, allowedRoles) &&
      !location.state?.back
    ) {
      navigate(to, { replace: true });
    } else {
      setShow(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if (disabled) {
      return;
    }
    if (show) {
      onShow();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  return <>{(show || disabled) && children}</>;
};

export default CheckBack;
