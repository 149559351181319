import React, { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Form } from "react-bootstrap";
import ConfirmModal from "../../components/ConfirmModal";
import ModalForm from "../../components/ModalForm";
import backend from "../../backend";
import { useNavigate } from "react-router-dom";
import { getTimezone } from "../../utils";
import Select from "react-select";

const MachineForm = React.forwardRef(({ scheduleItem }, ref) => {
  const navigate = useNavigate();

  const confirmRef = useRef();
  const [affected, setAffected] = useState([]);
  const [machines, setMachines] = useState([]);
  const [machine, setMachine] = useState("");
  const [currentMachine, setCurrentMachine] = useState("");

  const getMachines = async () => {
    try {
      const machinesData = (
        await backend.get(`machine/scheduleItem/${scheduleItem._id}`)
      ).data;
      const machineIndex = machinesData.findIndex(
        v => v._id === scheduleItem.machine
      );

      setCurrentMachine(machinesData.splice(machineIndex, 1)[0]);
      setMachines(
        machinesData.map(v => ({
          ...v,
          value: v._id,
          label: v.name,
        }))
      );
    } catch (err) {
      console.log(err);
    }
  };

  const testSubmit = async () => {
    if (!machines.length) {
      return;
    }

    if (!machine) {
      throw new Error("Faltan campos por completar");
    }

    // setAffected(
    //   (
    //     await backend.get(
    //       `schedule/${scheduleItem.schedule._id}/affected/${priority}`
    //     )
    //   ).data
    // );
    await confirmRef.current?.waitConfirm();
  };

  const submit = async () => {
    try {
      console.log(machine);
      await backend.patch("schedule/machine", {
        _id: scheduleItem.schedule._id,
        machine: machine.value,
        scheduleItem: scheduleItem._id,
        timezone: getTimezone(),
      });
      navigate(0);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <ModalForm
        ref={ref}
        title={scheduleItem?.process.piece.name}
        submit={testSubmit}
        onShow={getMachines}
      >
        <div className="m3-col">
          <div>Máquina Actual: {currentMachine?.name}</div>
          {machines.length === 0 ? (
            <div className="text-warning">
              Este proceso solo puede usar una máquina
            </div>
          ) : (
            <Select
              className="flex-fill"
              styles={{
                option: styles => ({ ...styles, color: "black" }),

                input: styles => ({ ...styles, flexDirection: "row" }),
              }}
              onChange={setMachine}
              value={machine}
              options={machines}
              classNamePrefix="react-select"
            />
          )}
        </div>
      </ModalForm>
      <ConfirmModal onAccept={submit} ref={confirmRef}>
        {!!affected.length && (
          <div className="m3-col">
            <div>Las siguientes piezas se van a recalcular</div>
            {affected.map(v => (
              <div key={v._id}>
                <div className="fw-bold">
                  #{v.number} {v.name}
                </div>
                {v.schedules.map(vs => (
                  <div className="ms-3" key={vs._id}>
                    {vs.quantity} {vs.piece.name}
                  </div>
                ))}
              </div>
            ))}
          </div>
        )}
      </ConfirmModal>
    </>
  );
});

export default MachineForm;
