import React, { useRef, useState } from "react";
import Form from "react-bootstrap/Form";
import Select from "react-select";

import backend from "../../backend";
import ModalForm from "../../components/ModalForm";
import CheckBack from "../../components/CheckBack";

import { useLocation, useNavigate } from "react-router-dom";

const MachineForm = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const _id = location.state?._id;

  const formRef = useRef();
  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const [types, setTypes] = useState([]);
  const [status, setStatus] = useState("");

  const submit = async () => {
    if (!name || !type) {
      throw new Error("Faltan campos por completar");
    }

    if (_id) {
      const machine = (
        await backend.patch("machine", {
          _id,
          name,
          type: type.value,
          status,
        })
      ).data;
      navigate("/machines", { replace: true, state: { edit: machine } });
    } else {
      const machine = (
        await backend.post("machine", { name, type: type.value })
      ).data;

      navigate("/machines", { replace: true, state: { new: machine } });
    }
  };

  const resetForm = () => {
    setName("");
    setType("");
    setStatus("");
  };

  const fillForm = async () => {
    try {
      const data = (await backend.get(`machine/${_id}`)).data;
      setName(data.name);
      setType({ value: data.type._id, label: data.type.type });
      setStatus(data.status);
    } catch (err) {
      console.log(err);
    }
  };

  const getTypes = async () => {
    try {
      const typesData = (await backend.get("machineType")).data;
      setTypes(typesData.map(v => ({ value: v._id, label: v.type })));
    } catch (err) {
      console.log(err);
    }
  };

  const handleShow = async () => {
    await getTypes();
    if (_id) {
      await fillForm();
    } else {
      resetForm();
    }
  };

  const goBack = async () => {
    await new Promise(r => setTimeout(r, 100));
    navigate(-1);
  };

  return (
    <CheckBack to="/machines" onShow={() => formRef.current?.show()}>
      <ModalForm
        ref={formRef}
        title={_id ? "Editar Máquina" : "Nueva Máquina"}
        submit={submit}
        onShow={handleShow}
        onHide={goBack}
      >
        <div className="m3-col">
          <Select
            styles={{
              option: styles => ({ ...styles, color: "black" }),
              control: styles => ({
                ...styles,
                flexDirection: "row",
                minWidth: "200px",
              }),
              input: styles => ({ ...styles, flexDirection: "row" }),
            }}
            onChange={v => setType(v)}
            value={type}
            options={types}
            classNamePrefix="react-select"
          />
          <Form.Group>
            <Form.Control
              onChange={e => setName(e.target.value)}
              value={name}
              placeholder="Nombre"
              required
            />
          </Form.Group>
          {_id && (
            <Form.Group className="mt-3">
              <Form.Select
                onChange={e => setStatus(e.target.value)}
                value={status}
                placeholder="Estatus"
                required
              >
                <option value="" disabled>
                  Estatus
                </option>
                <option value="active">Activo</option>
                <option value="inactive">Inactivo</option>
              </Form.Select>
            </Form.Group>
          )}
        </div>
      </ModalForm>
    </CheckBack>
  );
};

export default MachineForm;
