import React from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { Link, useLocation, useNavigate } from "react-router-dom";

const SideBarIcon = ({ icon, path, name = "", size = "28px" }) => {
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <OverlayTrigger placement="right" overlay={<Tooltip>{name}</Tooltip>}>
      <Link
        to={`/${path}`}
        className={`text-decoration-none p-4 ${
          location.pathname.includes(path)
            ? "bg-primary text-white"
            : "text-white"
        }`}
        style={{ cursor: "pointer", fontSize: size }}
      >
        <i className={`bi bi-${icon}`} />
      </Link>
    </OverlayTrigger>
  );
};

export default SideBarIcon;
