import React, { useRef, useState } from "react";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { v4 as uuidv4 } from "uuid";
import { useLocation, useNavigate, useNavigationType } from "react-router-dom";

import backend from "../../backend";
import { formatInt, formatPrice } from "../../utils";

import CheckBack from "../../components/CheckBack";
import WaitAsync from "../../components/WaitAsync";
import PriceInput from "../../components/PriceInput";
import PiecePrice from "../../components/PiecePrice";
import ButtonGroupFilter from "../../components/ButtonGroupFilter";
import LoadingOverlay from "../../components/LoadingOverlay";
import IntegerInput from "../../components/IntegerInput";
import FileInput from "../../components/FileInput";

const PieceForm = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const navigationType = useNavigationType();

  const _id = location.state?._id;

  const loadingOverlayRef = useRef();

  const getPiece = async () => {
    try {
      const piece = (await backend.get(`piece/${_id}`)).data;
      setName(piece.name);
      setSku(piece.sku);
      setDetails(piece.details);
      setMaterial(piece.material);
      setGeometry(piece.geometry);
      setMaterialPrice(formatPrice(piece.materialPrice) || "");
      setBlueprint(piece.blueprint);
      setFirstBlueprint(piece.blueprint);
      setMinStock(piece.minStock || "");
      setPrice(formatPrice(piece.price) || "");

      // Fill missing constants
      const currentConstants = (await backend.get("constants")).data;

      for (const price of currentConstants.prices) {
        if (
          !piece.constants.prices.find(v => v.machineType === price.machineType)
        ) {
          piece.constants.prices.push(price);
        }
      }

      setConstants(piece.constants);
      const newProcesses = piece.processes;
      for (const process of newProcesses) {
        if (process.external) {
          process.machineType = "";
          process.machines = [];
        } else {
          process.price = "";
          process.machineType.value = process.machineType._id;
          process.machineType.label = process.machineType.type;
          for (const machine of process.machines) {
            machine.value = machine._id;
            machine.label = machine.name;
          }
        }
      }
      setProcesses(newProcesses);
    } catch (err) {
      console.log(err);
    }
  };

  const [validated, setValidated] = useState(false);
  const [error, setError] = useState();
  const [fileError, setFileError] = useState();

  const getNewProcess = () => ({
    _id: uuidv4(),
    fakeId: true,
    machineType: "",
    machines: [],
    duration: "",
    name: "",
    external: false,
    price: "",
    priceSelected: "price",
  });

  const [name, setName] = useState("");
  const [details, setDetails] = useState("");
  const [sku, setSku] = useState("");
  const [material, setMaterial] = useState("");
  const [geometry, setGeometry] = useState("");
  const [materialPrice, setMaterialPrice] = useState("");
  const [blueprint, setBlueprint] = useState("");
  const [processes, setProcesses] = useState([getNewProcess()]);
  const [price, setPrice] = useState("");
  const [minStock, setMinStock] = useState("");

  const blueprintRef = useRef();

  const [machineTypes, setMachineTypes] = useState([]);
  const [constants, setConstants] = useState();
  const [drive, setDrive] = useState();

  const [firstBlueprint, setFirstBlueprint] = useState();

  const getMachineTypes = async () => {
    try {
      const machineTypesData = (await backend.get("machineType")).data;
      setMachineTypes(
        machineTypesData.map(v => ({
          ...v,
          value: v._id,
          label: v.type,
        }))
      );
    } catch (err) {
      console.log(err);
    }
  };

  const getConstants = async () => {
    try {
      setConstants((await backend.get("constants")).data);
    } catch (err) {
      console.log(err);
    }
  };

  const getDrive = async () => {
    try {
      setDrive((await backend.get("data/drive")).data);
    } catch (err) {
      console.log(err);
    }
  };

  const getData = async () => {
    await getDrive();
    await getMachineTypes();
    if (location.pathname.includes("new")) {
      await getConstants();
    }
    if (location.pathname.includes("edit")) {
      await getPiece();
    }
  };

  const handleBlueprintUpload = async e => {
    setFileError("");
    try {
      const newFile = e.target.files[0];
      if (newFile.name.split(".").slice(-1)[0] !== "pdf") {
        setFileError("Debes subir un pdf");
      }
      const formData = new FormData();
      formData.append("blueprint", newFile);
      const newBlueprint = (await backend.post("piece/blueprint", formData))
        .data;
      if (firstBlueprint?.id && blueprint.id !== firstBlueprint.id) {
        await handleBlueprintRemove();
      }
      setBlueprint(newBlueprint);
    } catch (err) {
      console.log(err);
      setFileError(err.message);
    }
  };

  const handleBlueprintRemove = async () => {
    try {
      await backend.delete("piece/blueprint", { data: { blueprint } });
      setBlueprint("");
    } catch (err) {
      console.log(err);
    }
  };

  const handleProcessPriceSelectedChanged = (i, priceSelected) => {
    const newProcesses = JSON.parse(JSON.stringify(processes));
    newProcesses[i].priceSelected = priceSelected;
    setProcesses(newProcesses);
  };

  const handleProfitChanged = profit => {
    setConstants(p => ({ ...p, profit }));
  };

  const handleConstantsUpdate = async () => {
    try {
      setConstants((await backend.get("constants")).data);
    } catch (err) {
      console.log(err);
    }
  };

  const cancel = () => {
    // delete current blueprint if different from original
    if (!firstBlueprint?.id || firstBlueprint.id !== blueprint?.id) {
      handleBlueprintRemove();
    }

    navigationType === "POP"
      ? navigate("/pieces", { replace: true })
      : navigate(-1);
  };

  const submit = async () => {
    // delete original blueprint if different from current
    // this seems to be happening already
    // if (firstBlueprint?.id && firstBlueprint.id !== blueprint?.id) {
    //   await backend.delete("piece/blueprint", {
    //     data: { blueprint: firstBlueprint },
    //   });
    // }

    loadingOverlayRef.current?.show();
    setValidated(false);
    setError(undefined);

    try {
      if (!sku || !name || !price || fileError) {
        throw new Error("Faltan campos por completar");
      }
      const processesData = JSON.parse(JSON.stringify(processes));
      for (let i = 0; i < processesData.length; ++i) {
        if (processesData[i].fakeId) {
          delete processesData[i]._id;
        }
        if (processesData[i].external) {
          delete processesData[i].machineType;
          delete processesData[i].machines;
          processesData[i].price = +formatInt(processesData[i].price);
        } else {
          delete processesData[i].price;
          processesData[i].machineType = processesData[i].machineType.value;
        }
      }

      for (const process of processes) {
        if (!process.name || process.duration === "") {
          throw new Error("Faltan campos por completar");
        }

        if (process.external) {
          if (!process.price) {
            throw new Error("Faltan campos por completar");
          }
        } else {
          if (!process.machineType) {
            throw new Error("Falta elegir el tipo de máquina");
          }
          if (!process.machines.length) {
            throw new Error("Falta elegir al menos una máquina capaz");
          }
        }
      }

      if (_id) {
        const editPiece = (
          await backend.patch("piece", {
            _id,
            name,
            details,
            sku,
            material,
            geometry,
            materialPrice: materialPrice ? +formatInt(materialPrice) : "",
            blueprint,
            price: +formatInt(price),
            processes: processesData,
            minStock,
            constants,
          })
        ).data;
        navigate("/pieces", { replace: true, state: { edit: editPiece } });
      } else {
        const newPiece = (
          await backend.post("piece", {
            name,
            details,
            sku,
            material,
            geometry,
            materialPrice: materialPrice ? +formatInt(materialPrice) : "",
            blueprint,
            price: +formatInt(price),
            processes: processesData,
            status: "active",
            minStock,
            constants,
          })
        ).data;

        navigate("/pieces", { replace: true, state: { new: newPiece } });
      }
    } catch (err) {
      console.log(err);
      setError(err.message);
    }
    setValidated(true);
    loadingOverlayRef.current?.hide();
  };
  return (
    <CheckBack to="/pieces" disabled={location.pathname.includes("new")}>
      <WaitAsync func={getData}>
        <div className="fw-bold fs-1">
          {_id ? `Pieza ${sku}` : "Nueva Pieza"}
        </div>
        <Form
          noValidate
          validated={validated}
          onSubmit={e => {
            e.preventDefault();
            e.stopPropagation();
            submit();
          }}
        >
          <div className="m3-col">
            <div className="m3-row">
              <div className="m2-col w-50">
                <Form.Group>
                  <Form.Control
                    onChange={e => setName(e.target.value)}
                    value={name}
                    placeholder="Nombre"
                    required
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Control
                    onChange={e => setSku(e.target.value)}
                    value={sku}
                    placeholder="Sku"
                    required
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Control
                    onChange={e => setDetails(e.target.value)}
                    value={details}
                    placeholder="Detalles"
                  />
                </Form.Group>
                {drive && (
                  <FileInput
                    url={blueprint?.url}
                    onChange={handleBlueprintUpload}
                    onRemove={handleBlueprintRemove}
                    accept=".pdf"
                  >
                    <i className="bi bi-file-pdf align-self-center" />
                    <div className="fw-bold">Plano</div>
                  </FileInput>
                )}

                <Form.Group>
                  <IntegerInput
                    onChange={e => setMinStock(e.target.value)}
                    value={minStock}
                    placeholder="Cantidad mínima en stock"
                  />
                </Form.Group>
              </div>
              <div className="m2-col w-50">
                <div className="fw-bold fs-3">Material</div>
                <Form.Group>
                  <Form.Control
                    onChange={e => setMaterial(e.target.value)}
                    value={material}
                    placeholder="Material"
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Control
                    onChange={e => setGeometry(e.target.value)}
                    value={geometry}
                    placeholder="Geometría"
                  />
                </Form.Group>
              </div>
            </div>

            <div className="fw-bold fs-3">Procesos</div>
            <div
              className="grid"
              style={{
                gap: "16px",
                gridTemplateColumns: "repeat( auto-fill, minmax(300px, 1fr) )",
              }}
            >
              {processes?.map((v, i) => (
                <Item
                  key={i}
                  index={i}
                  item={v}
                  setItems={setProcesses}
                  machineTypes={machineTypes}
                />
              ))}
            </div>
            <div className="fw-bold fs-3">Precio</div>
            <PiecePrice
              piece={{ processes, constants, materialPrice, price }}
              onProcessPriceSelectedChanged={handleProcessPriceSelectedChanged}
              onMaterialPriceChanged={setMaterialPrice}
              onProfitChanged={handleProfitChanged}
              onPriceChanged={setPrice}
              updateConstants={handleConstantsUpdate}
              editable
            />

            {error && (
              <div className="invalid-feedback text-center">{error}</div>
            )}
            <div className="flex-row align-self-center">
              <Button variant="secondary" onClick={cancel}>
                Cancelar
              </Button>
              <Button variant="primary ms-3" onClick={submit}>
                {location.pathname.includes("new") ? "Crear" : "Aceptar"}
              </Button>
            </div>
            <LoadingOverlay ref={loadingOverlayRef} />
          </div>
        </Form>
        <Form.Control
          onChange={handleBlueprintUpload}
          type="file"
          accept=".pdf"
          className="d-none"
          ref={blueprintRef}
        />
      </WaitAsync>
    </CheckBack>
  );
};

const Item = ({ index, item, setItems, machineTypes }) => {
  const { machineType, duration, name, machines, external, price } = item;

  const set = (k, i) => v => {
    setItems(p => {
      const n = [...p];
      n[i] = { ...n[i], [k]: v };
      return n;
    });
  };

  const setMachineType = set("machineType", index);
  const setDuration = set("duration", index);
  const setName = set("name", index);
  const setMachines = set("machines", index);
  const setExternal = set("external", index);
  const setPrice = set("price", index);

  const remove = () => {
    setItems(p => {
      const n = [...p];
      n.splice(index, 1);
      return n;
    });
  };

  const [machineOptions, setMachineOptions] = useState([]);
  const [machineOptionsLoading, setMachineOptionsLoading] = useState(false);

  const getMachineOptions = async mt => {
    setMachines([]);
    setMachineOptionsLoading(true);
    try {
      const machinesData = (await backend.get(`machine/type/${mt._id}`)).data;
      setMachineOptions(
        machinesData.map(v => ({
          ...v,
          value: v._id,
          label: v.name,
        }))
      );
    } catch (err) {
      console.log(err);
    }
    setMachineOptionsLoading(false);
  };

  const getNewProcess = () => ({
    _id: uuidv4(),
    fakeId: true,
    machineType: "",
    machines: [],
    duration: "",
    name: "",
    external: false,
    price: "",
    priceSelected: "price",
  });

  const setProcessLeft = () => {
    setItems(p => {
      const n = [...p];
      n.splice(index, 0, getNewProcess());
      return n;
    });
  };

  const setProcessRight = () => {
    setItems(p => {
      const n = [...p];
      n.splice(index + 1, 0, getNewProcess());
      return n;
    });
  };

  return (
    <Card bg="dark">
      <Card.Body>
        <div className="m2-col flex-fill">
          <div className="m2-row">
            <div
              className="text-end fw-bold fst-italic text-muted user-select-none"
              style={{
                fontSize: "24px",
              }}
            >
              {`#${index + 1}`}
            </div>
            <ButtonGroupFilter
              options={{
                [false]: "Interno",
                [true]: "Externo",
              }}
              tabState={[external, setExternal]}
              className="flex-fill"
              parseKey={v => v === "true"}
            />
            <Button
              variant="secondary flex-center ms-2"
              onClick={remove}
              style={{ width: 40, height: 40 }}
            >
              <i className="bi bi-x-lg" />
            </Button>
          </div>
          <Form.Group className="">
            <Form.Control
              onChange={e => setName(e.target.value)}
              value={name}
              placeholder="Nombre"
              required
            />
          </Form.Group>

          {external ? (
            <>
              <div className="m3-row flex-fill">
                <Form.Group className="flex-fill">
                  <Form.Control
                    onChange={e => setDuration(formatInt(e.target.value))}
                    value={duration}
                    placeholder="Tiempo (días)"
                    required
                  />
                </Form.Group>
                <PriceInput
                  className=""
                  onChange={v => setPrice(v)}
                  value={price}
                  required
                />
              </div>
            </>
          ) : (
            <>
              <div className="m2-row">
                <Form.Group>
                  <Form.Control
                    onChange={e => setDuration(formatInt(e.target.value))}
                    value={duration}
                    placeholder="Duración (m)"
                    style={{ width: "120px" }}
                    required
                  />
                </Form.Group>
                <Select
                  className="flex-fill"
                  styles={{
                    option: styles => ({ ...styles, color: "black" }),

                    input: styles => ({ ...styles, flexDirection: "row" }),
                  }}
                  onChange={v => {
                    setMachineType(v);
                    getMachineOptions(v);
                  }}
                  value={machineType}
                  options={machineTypes}
                  classNamePrefix="react-select"
                />
              </div>
              <div>
                <div className="fw-bold">Máquinas Capaces</div>
                <Select
                  styles={{
                    option: styles => ({ ...styles, color: "black" }),
                    control: styles => ({
                      ...styles,
                      flexDirection: "row",
                      minWidth: "200px",
                    }),
                    input: styles => ({ ...styles, flexDirection: "row" }),
                    multiValue: styles => ({ ...styles, color: "black" }),
                  }}
                  isMulti
                  value={machines}
                  onChange={setMachines}
                  isLoading={machineOptionsLoading}
                  options={machineOptions}
                  classNamePrefix="react-select"
                  isDisabled={!machineType}
                />
              </div>
            </>
          )}
          <div
            style={{
              justifyContent: "space-between",
            }}
            className="d-flex justify-content-between flex-row"
          >
            <Button
              style={{ width: 40, height: 40 }}
              variant="secondary"
              className="flex-center position-relative"
              onClick={setProcessLeft}
            >
              <i className="bi bi-plus-lg" />
              <i
                className="bi bi-arrow-left-short position-absolute"
                style={{ top: 2, left: 2 }}
              />
            </Button>
            <Button
              style={{ width: "40px" }}
              variant="secondary"
              className="flex-center position-relative"
              onClick={setProcessRight}
            >
              <i className="bi bi-plus-lg" />
              <i
                className="bi bi-arrow-right-short position-absolute"
                style={{ top: 2, right: 2 }}
              />
            </Button>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default PieceForm;
