import React from "react";
import Form from "react-bootstrap/Form";
import { formatInt, formatPrice } from "../utils";

const PriceInput = ({
  onChange = v => {},
  value,
  required,
  style,
  inputStyle = {},
  className = "",
  placeholder = "Precio",
  left,
}) => {
  return (
    <Form.Group className={`position-relative ${className}`} style={style}>
      <Form.Control
        onChange={e => onChange(formatInt(e.target.value))}
        value={value}
        onKeyUp={async e => {
          if (!e.target.value.match(/\./g)) {
            return;
          }
          onChange(formatInt(e.target.value));
          await new Promise(r => setTimeout(r, 0));
          e.target.setSelectionRange(0, e.target.value.length);
        }}
        onMouseDown={async e => {
          if (!e.target.value.match(/\./g)) {
            return;
          }
          await new Promise(r => setTimeout(r, 0));
          const selectionStart = e.target.selectionStart;
          const value = e.target.value;
          const dots = (value.slice(0, selectionStart).match(/\./g) || [])
            .length;
          const newSelectionStart = selectionStart - dots;
          onChange(formatInt(e.target.value));

          await new Promise(r => setTimeout(r, 0));
          e.target.setSelectionRange(newSelectionStart, newSelectionStart);
        }}
        onBlur={async e => {
          onChange(formatPrice(e.target.value));
          await new Promise(r => setTimeout(r, 0));
          e.target.scrollLeft = e.target.scrollWidth;
        }}
        placeholder={placeholder}
        style={{
          paddingLeft: "21px",
          textAlign: left ? "left" : "right",
          ...inputStyle,
        }}
        required={required}
      />
      <div
        className="user-select-none"
        style={{
          position: "absolute",
          top: "7px",
          left: "13px",
          color: `var(--bs-gray-${value ? 3 : 6}00)`,
        }}
      >
        $
      </div>
    </Form.Group>
  );
};

export default PriceInput;
