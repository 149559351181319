import Button from "react-bootstrap/Button";

import React, { useEffect, useRef, useState } from "react";
import SearchBar from "../../components/SearchBar";
import WaitAsync from "../../components/WaitAsync";
import backend from "../../backend";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import ModalForm from "../../components/ModalForm";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import useTitle from "../../hooks/useTitle";
import ButtonGroupFilter from "../../components/ButtonGroupFilter";

const Orders = () => {
  useTitle("Pedidos");
  const navigate = useNavigate();
  const location = useLocation();

  const [filter, setFilter] = useState("");
  const [tab, setTab] = useState("");

  const [orders, setOrders] = useState([]);
  const [drive, setDrive] = useState();

  const getOrders = async () => {
    try {
      setOrders((await backend.get("order")).data);
    } catch (err) {
      console.log(err);
    }
  };

  const getDrive = async () => {
    try {
      setDrive((await backend.get("data/drive")).data);
    } catch (err) {
      console.log(err);
    }
  };

  const getData = async () => {
    await getDrive();
    await getOrders();
  };

  // Add modal
  const addRef = useRef();
  const [name, setName] = useState("");

  const addSubmit = async () => {
    if (!name) {
      throw new Error("Faltan campos por completar");
    }
    const newOrder = (await backend.post("order", { name })).data;
    setOrders(p => [newOrder, ...p]);
  };

  const addReset = () => {
    setName("");
  };

  useEffect(() => {
    if (location.state?.new) {
      const order = location.state?.new;
      setOrders(p => [...p, order]);
      navigate("", { replace: true });
    }
    if (location.state?.edit) {
      const order = location.state?.edit;
      const updatedOrders = [...orders];
      for (let i = 0; i < updatedOrders.length; ++i) {
        if (updatedOrders[i]._id === order._id) {
          updatedOrders[i] = order;
          break;
        }
      }
      setOrders(updatedOrders);
      navigate("", { replace: true });
    }
    if (location.state?.working) {
      const order = location.state?.working;
      const updatedOrders = [...orders];
      for (let i = 0; i < updatedOrders.length; ++i) {
        if (updatedOrders[i]._id === order._id) {
          updatedOrders[i] = order;
          break;
        }
      }
      setOrders(updatedOrders);
      navigate("", { replace: true });
    }
    if (location.state?.finish) {
      const order = location.state?.finish;
      const updatedOrders = [...orders];
      for (let i = 0; i < updatedOrders.length; ++i) {
        if (updatedOrders[i]._id === order._id) {
          updatedOrders[i] = order;
          break;
        }
      }
      setOrders(updatedOrders);
      navigate("", { replace: true });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <div>
      <div className=" flex-row align-items-end pb-3">
        <div className="fw-bold fs-1 flex-fill">Pedidos</div>
        <Link to="quotation/new" className="btn btn-primary me-3 ">
          Agregar Cotización
        </Link>
        <SearchBar searchState={[filter, setFilter]} />
      </div>
      <ButtonGroupFilter
        className="mt-3"
        options={{
          "": "Todos",
          created: "Creados",
          working: "En Proceso",
          completed: "Completados",
        }}
        tabState={[tab, setTab]}
        buttonClassName="w-120px"
      />

      <WaitAsync func={getData}>
        {orders
          .filter(
            v =>
              (v.name.includes(filter) ||
                v.client?.name.includes(filter) ||
                v.client?.rut.includes(filter)) &&
              (!tab || tab === v.status)
          )
          .map((v, i) => (
            <Order key={i} order={v} drive={drive} />
          ))}
        {orders.length === 0 ? (
          <div className="fs-5">Actualmente no tienes pedidos</div>
        ) : (
          <></>
        )}
      </WaitAsync>

      <ModalForm
        ref={addRef}
        title="Nuevo Pedido"
        submit={addSubmit}
        onShow={addReset}
      >
        <Form.Group>
          <Form.Control
            onChange={e => setName(e.target.value)}
            value={name}
            placeholder="Nombre"
            required
          />
        </Form.Group>
      </ModalForm>
      <Outlet />
    </div>
  );
};

const Order = ({ order, drive }) => {
  const navigate = useNavigate();
  const { _id, name, number, status } = order;

  const getIcon = key => {
    switch (key) {
      case "created":
        return "receipt";
      case "working":
        return "clock";
      case "completed":
        return "calendar-check";
      default:
        return "question-circle";
    }
  };

  const view = () => navigate("view", { state: { _id, back: true } });
  const files = () => navigate("files", { state: { _id, back: true } });
  return (
    <div className="flex-row mt-3">
      <Card className="flex-row m3-row flex-fill px-3 py-2">
        <i className={`bi bi-${getIcon(status)} align-self-center`} />

        <div className="fw-bold">{`#${number}`}</div>
        <div className="fw-bold">{name}</div>
      </Card>
      {drive && (
        <Button variant="warning justify-content-center ms-1" onClick={files}>
          <i className="bi bi-files" />
        </Button>
      )}
      <Button variant="primary justify-content-center ms-1" onClick={view}>
        <i className="bi bi-eye" />
      </Button>
    </div>
  );
};

export default Orders;
