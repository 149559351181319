import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Tooltip from "react-bootstrap/Tooltip";
import backend from "../../backend";
import WaitAsync from "../../components/WaitAsync";

import { useNavigate } from "react-router-dom";
import {
  formatDate,
  getLimitedToken,
  setLimitedToken,
  formatISODate,
  getNextDay,
  getPrevDay,
  getTimezone,
  hash,
  isWeekend,
  lightness,
  mix,
} from "../../utils";
import { DAYS_SHORT } from "../../constants";
import { useRef } from "react";
import useTitle from "../../hooks/useTitle";
import { ScrollSync, ScrollSyncPane } from "react-scroll-sync";
import { Resizable } from "re-resizable";
import LoadingOverlay from "../../components/LoadingOverlay";
import { Badge, OverlayTrigger, Popover } from "react-bootstrap";
import { createContext } from "react";
import { useContext } from "react";
import ScheduleItemModal from "./ScheduleItemModal";
import PriorityForm from "./PriorityForm";
import DatesForm from "./DatesForm";
import MachineForm from "./MachineForm";
import ConfirmModal from "../../components/ConfirmModal";

const hours = [7, 8, 9, 10, 11, 12, 13, 14, 15];

const ChartContext = createContext({});

const Chart = () => {
  useTitle("Home");
  const navigate = useNavigate();

  const [machineTypes, setMachineTypes] = useState([]);
  const [externalScheduleItems, setExternalScheduleItems] = useState([]);
  // fast access {_id : { machineTypeIndex, machineIndex } }
  const [machineMap, setMachineMap] = useState();

  const loadingOverlayRef = useRef();

  const updateMachineMap = machineTypesArray => {
    const newMachineMap = {};
    for (
      let machineTypeIndex = 0;
      machineTypeIndex < machineTypesArray.length;
      ++machineTypeIndex
    ) {
      for (
        let machineIndex = 0;
        machineIndex < machineTypesArray[machineTypeIndex].machines.length;
        ++machineIndex
      ) {
        newMachineMap[
          machineTypesArray[machineTypeIndex].machines[machineIndex]._id
        ] = { machineTypeIndex, machineIndex };
      }
      setMachineMap(newMachineMap);
    }
  };

  const getData = async () => {
    try {
      const newMachineTypes = (await backend.get("machineType/machines")).data;
      updateMachineMap(newMachineTypes);
      setMachineTypes(newMachineTypes);
    } catch (err) {
      console.log(err);
    }
  };

  const addScheduleItem = (scheduleItem, machineTypesArray) => {
    if (!scheduleItem.machine) {
      console.log(`scheduleItem ${scheduleItem._id} doesn't have a machine`);
      return;
    }
    const { machineTypeIndex, machineIndex } = machineMap[scheduleItem.machine];
    machineTypesArray[machineTypeIndex].machines[
      machineIndex
    ].scheduleItems.push(scheduleItem);
  };

  const loadScheduleItems = async () => {
    loadingOverlayRef.current?.show();
    try {
      const scheduleItems = (
        await backend.get(
          `scheduleItem/pending?${new URLSearchParams({
            day: date,
            timezone: getTimezone(date),
          }).toString()}`
        )
      ).data;

      const newMachineTypes = JSON.parse(JSON.stringify(machineTypes));
      // clean old schedule items
      for (const machineType of newMachineTypes) {
        for (const machine of machineType.machines) {
          machine.scheduleItems = [];
        }
      }

      // load new schedule items
      for (const scheduleItem of scheduleItems) {
        addScheduleItem(scheduleItem, newMachineTypes);
      }
      setMachineTypes(newMachineTypes);

      // load external processes
      setExternalScheduleItems(
        (
          await backend.get(
            `scheduleItem/pending/external?${new URLSearchParams({
              day: date,
              timezone: getTimezone(date),
            }).toString()}`
          )
        ).data
      );
    } catch (err) {
      console.log(err);
    }
    loadingOverlayRef.current?.hide();
  };

  const dateCache = useRef(getLimitedToken("date"));

  // Date
  const [date, _setDate] = useState(
    dateCache.current ? new Date(dateCache.current) : new Date()
  );

  const setDate = newDate => {
    setLimitedToken("date", newDate, 300000);
    _setDate(newDate);
  };

  const chartRef = useRef();
  const hoursRef = useRef();

  const [chartHorizontalScroll, setChartHorizontalScroll] = useState(true);

  useEffect(() => {
    const openDate = new Date(date);
    openDate.setHours(hours[0], 0, 0, 0);

    const closeDate = new Date(date);
    closeDate.setHours(hours[hours.length - 1] + 1, 0, 0, 0);
    setChartContext(p => ({
      ...p,
      handlePrevDay: () => handlePrevDay(true),
      handleNextDay: () => handleNextDay(true),
      openDate,
      closeDate,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  const priorityRef = useRef();
  const datesRef = useRef();
  const machineRef = useRef();
  const [scheduleItemForm, setScheduleItemForm] = useState(); // {scheduleItem, ref}

  const handlePriorityChange = scheduleItem => {
    setScheduleItemForm({ scheduleItem, ref: priorityRef });
  };

  const handleDatesChange = scheduleItem => {
    setScheduleItemForm({ scheduleItem, ref: datesRef });
  };

  const handleMachineChange = scheduleItem => {
    setScheduleItemForm({ scheduleItem, ref: machineRef });
  };

  useEffect(() => {
    scheduleItemForm?.ref.current?.show();
  }, [scheduleItemForm]);

  const [chartContext, setChartContext] = useState({
    handlePriorityChange,
    handleDatesChange,
    handleMachineChange,
  });

  useEffect(() => {
    if (machineMap) {
      loadScheduleItems();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, machineMap]);

  const dateRef = useRef();

  const addDays = d => {
    const newDate = new Date(date.getTime());
    newDate.setDate(newDate.getDate() + d);
    setDate(newDate);
  };

  const handlePrevDay = (scroll = false) => {
    let testDate = new Date(date);
    let days = 0;
    do {
      days -= 1;
      testDate = getPrevDay(testDate);
    } while (isWeekend(testDate));
    addDays(days);
    if (scroll) {
      scrollChartRight();
    }
  };

  const handleNextDay = (scroll = false) => {
    let testDate = new Date(date);
    let days = 0;
    do {
      days += 1;
      testDate = getNextDay(testDate);
    } while (isWeekend(testDate));
    addDays(days);
    if (scroll) {
      scrollChartLeft();
    }
  };

  const scrollChartLeft = () => {
    setChartHorizontalScroll(false);
    chartRef.current.scrollLeft = 0;
    hoursRef.current.scrollLeft = 0;
    setChartHorizontalScroll(true);
  };

  const scrollChartRight = () => {
    setChartHorizontalScroll(false);
    chartRef.current.scrollLeft = chartRef.current.scrollWidth;
    setChartHorizontalScroll(true);
    chartRef.current.scrollLeft = chartRef.current.scrollWidth;
  };

  const handleDateChange = dateString => {
    let newDate = new Date(dateString);
    newDate.setMinutes(newDate.getMinutes() + newDate.getTimezoneOffset());
    // check weekday
    if (isWeekend(newDate)) {
      if (newDate.getDay() === 6) {
        newDate = getPrevDay(newDate);
      }
      if (newDate.getDay() === 0) {
        newDate = getNextDay(newDate);
      }
    }

    setDate(newDate);
  };

  // External processes

  const [showExternal, setShowExternal] = useState(false);

  //

  // Style

  const [hourSize, setHourSize] = useState(150);

  const confirmRef = useRef();

  const handleTrash = async () => {
    try {
      await confirmRef.current.waitConfirm();
      loadingOverlayRef.current?.show();
      await backend.post("delete");
      navigate(0);
    } catch (err) {
      console.log(err);
      loadingOverlayRef.current?.hide();
    }
  };

  return (
    <ChartContext.Provider value={chartContext}>
      <ScrollSync proportional={false}>
        <WaitAsync func={getData}>
          <div className="flex-fill overflow-auto">
            <div className="flex-row h-100">
              <Resizable
                enable={{
                  top: false,
                  right: true,
                  bottom: false,
                  left: false,
                  topRight: false,
                  bottomRight: false,
                  bottomLeft: false,
                  topLeft: false,
                }}
                defaultSize={{ width: localStorage.getItem("ms-lw") || 150 }}
                maxWidth={"50%"}
                minWidth={72}
                onResizeStop={(e, d, ref) => {
                  localStorage.setItem("ms-lw", ref.style.width);
                }}
              >
                <div
                  className="position-relative"
                  style={{ minHeight: 108, maxHeight: 108 }}
                >
                  <div
                    className="position-absolute h-100 border-bottom"
                    style={{ width: 16, bottom: 0, right: 0 }}
                  />
                </div>

                <ScrollSyncPane group="vertical">
                  <div className="overflow-hidden">
                    {machineTypes.map((v, i) => (
                      <React.Fragment key={i}>
                        <div className="fw-bold p-2 pt-4 ps-0">
                          <div className="text-truncate">{v.type}</div>
                        </div>

                        {v.machines.map((mv, mi) => (
                          <div key={mi} className="p-2 py-4 position-relative">
                            <div className="text-truncate">{mv.name}</div>
                            <div
                              className="position-absolute h-100 border-bottom"
                              style={{
                                width: "calc(100% - 8px)",
                                bottom: 0,
                                right: 0,
                              }}
                            />
                          </div>
                        ))}
                      </React.Fragment>
                    ))}
                    <div style={{ minHeight: 50 }} />
                  </div>
                </ScrollSyncPane>
              </Resizable>

              <div className="overflow-auto">
                <div
                  className="m3-row justify-content-center align-items-stretch mb-2"
                  style={{ minHeight: 50, maxHeight: 50 }}
                >
                  <Button
                    variant="primary justify-content-center"
                    onClick={() => handlePrevDay(false)}
                  >
                    <i className="bi bi-chevron-left fs-4" />
                  </Button>
                  <div className="position-relative" style={{ minWidth: 200 }}>
                    <Button
                      variant="primary fw-bold fs-4 flex-center"
                      onClick={() => dateRef.current?.showPicker()}
                    >{`${DAYS_SHORT[date.getDay()]} ${formatDate(
                      date
                    )}`}</Button>
                    <input
                      className="position-absolute"
                      ref={dateRef}
                      onChange={e => handleDateChange(e.target.value)}
                      value={formatISODate(date)}
                      type="date"
                      style={{
                        width: "100%",
                        height: "100%",
                        top: 0,
                        left: 0,
                        pointerEvents: "none",
                        opacity: 0,
                        zIndex: -99,
                      }}
                    />
                  </div>
                  <Button
                    variant="primary justify-content-center"
                    onClick={() => handleNextDay(false)}
                  >
                    <i className="bi bi-chevron-right fs-4 " />
                  </Button>
                  <Button
                    variant="primary justify-content-center"
                    onClick={handleTrash}
                  >
                    <i className="bi bi-trash fs-4 " />
                  </Button>
                </div>
                <ScrollSyncPane group="horizontal" attachTo={hoursRef}>
                  <div
                    ref={hoursRef}
                    className="flex-fill overflow-hidden"
                    style={{ minHeight: 50, maxHeight: 50 }}
                  >
                    <div className="flex-row flex-fill">
                      <div
                        className="border-bottom user-select-none"
                        style={{ minWidth: 25 }}
                      />
                      {hours.map((v, i) => (
                        <div
                          key={i}
                          style={{
                            width: hourSize,
                            minWidth: hourSize,
                          }}
                          className="border-bottom user-select-none position-relative"
                        >
                          <div style={{ width: 0 }}>
                            <div
                              style={{
                                marginLeft: -50,
                                marginRight: -50,
                                textAlign: "center",
                              }}
                            >
                              {v}
                            </div>
                          </div>
                          <div
                            className="position-absolute border-start opacity-25 w-100"
                            style={{
                              height: 16,
                              bottom: 0,
                            }}
                          />
                        </div>
                      ))}
                      <div
                        className="border-bottom user-select-none position-relative"
                        style={{ minWidth: 25 }}
                      >
                        <div style={{ width: 0 }}>
                          <div
                            style={{
                              marginLeft: -50,
                              marginRight: -50,
                              textAlign: "center",
                            }}
                          >
                            {hours[hours.length - 1] + 1}
                          </div>
                        </div>
                        <div
                          className="position-absolute border-start opacity-25 w-100"
                          style={{
                            height: 16,
                            bottom: 0,
                          }}
                        />
                      </div>
                      <div style={{ minWidth: 20 }} />
                    </div>
                  </div>
                </ScrollSyncPane>
                <ScrollSyncPane
                  group={["horizontal", "vertical"]}
                  attachTo={chartRef}
                  enabled={chartHorizontalScroll}
                >
                  <div ref={chartRef} className="overflow-auto flex-fill">
                    <div className="position-relative">
                      <div className="position-absolute flex-row h-100">
                        <div style={{ minWidth: 25 }} />

                        {hours.map((v, i) => (
                          <div
                            key={i}
                            style={{
                              width: hourSize,
                              minWidth: hourSize,
                            }}
                            className="border-start opacity-25 user-select-none"
                          />
                        ))}
                        <div
                          className="border-start opacity-25 user-select-none"
                          style={{ minWidth: 25 }}
                        />
                      </div>
                      {machineTypes.map((v, i) => (
                        <React.Fragment key={i}>
                          <div
                            className="fw-bold py-2 pt-4"
                            style={{ width: 0 }}
                          >
                            <div className="text-truncate">{v.type}</div>
                          </div>

                          {v.machines.map((mv, mi) => (
                            <div key={mi} className="flex-row">
                              <div
                                className="py-4"
                                style={{
                                  maxWidth: 0,
                                }}
                              >
                                <div className="text-truncate">{mv.name}</div>
                              </div>
                              <div
                                className="justify-content-center position-relative overflow-hidden"
                                style={{
                                  minWidth: hourSize * hours.length + 50,
                                  maxWidth: hourSize * hours.length + 50,
                                }}
                              >
                                <div className="position-absolute h-100 w-100 border-bottom" />
                                {mv.scheduleItems?.map(sv => (
                                  <ScheduleItem
                                    key={`${sv.schedule.priority} ${sv.place}`}
                                    scheduleItem={sv}
                                    hourSize={hourSize}
                                  />
                                ))}
                              </div>
                            </div>
                          ))}
                        </React.Fragment>
                      ))}
                      <div style={{ height: 16 }} />
                    </div>
                  </div>
                </ScrollSyncPane>
              </div>
            </div>
          </div>
          {externalScheduleItems.length !== 0 && (
            <div
              className="t-02"
              style={{
                maxHeight: showExternal ? "30%" : "66px",
              }}
            >
              <Button
                className="flex-row flex-center mt-3"
                style={{ fontSize: 24 }}
                onClick={() => setShowExternal(p => !p)}
              >
                <i
                  className="bi bi-chevron-down t-02"
                  style={{ transform: `rotate(${showExternal ? -180 : 0}deg)` }}
                />
                <div className="flex-fill m3-row flex-center">
                  <div className="fw-bold">Procesos externos</div>
                  <Badge bg="dark">{externalScheduleItems.length}</Badge>
                </div>
                <i
                  className="bi bi-chevron-down t-02"
                  style={{ transform: `rotate(${showExternal ? 180 : 0}deg)` }}
                />
              </Button>
              <div className="overflow-scroll pt-3">
                <div
                  className="grid"
                  style={{
                    gridTemplateColumns: "auto auto auto auto auto auto",
                    // gap: 16,
                  }}
                >
                  <div className="fw-bold p-1">Pr</div>
                  <div className="fw-bold p-1">Po</div>
                  <div className="fw-bold p-1">Nombre</div>
                  <div className="fw-bold p-1">Fecha Inicio</div>
                  <div className="fw-bold p-1">Fecha Término</div>
                  <div className="fw-bold p-1" style={{ columnCount: 2 }}>
                    Pieza
                  </div>
                  {externalScheduleItems.map(v => (
                    <ExternalScheduleItem
                      key={`${v.schedule.priority} ${v.place}`}
                      scheduleItem={v}
                    />
                  ))}
                </div>
              </div>
            </div>
          )}

          <PriorityForm
            ref={priorityRef}
            scheduleItem={scheduleItemForm?.scheduleItem}
          />
          <DatesForm
            ref={datesRef}
            scheduleItem={scheduleItemForm?.scheduleItem}
          />
          <MachineForm
            ref={machineRef}
            scheduleItem={scheduleItemForm?.scheduleItem}
          />

          <ConfirmModal ref={confirmRef} />

          <LoadingOverlay ref={loadingOverlayRef} />
        </WaitAsync>
      </ScrollSync>
    </ChartContext.Provider>
  );
};

const ScheduleItem = ({ scheduleItem, hourSize }) => {
  const {
    _id,
    process: { name },
    startDate,
    endDate,
    schedule: { priority },
    place,
    priorityModifier,
    status,
  } = scheduleItem;

  const {
    openDate,
    closeDate,
    handlePrevDay,
    handleNextDay,
    handlePriorityChange,
    handleDatesChange,
    handleMachineChange,
  } = useContext(ChartContext);

  const [style, setStyle] = useState({
    position: 0,
    width: 0,
    left: false,
    right: false,
  });

  useEffect(() => {
    let position = 0;
    let width = 0;
    let left = false;
    let right = false;
    let localStartDate = new Date(startDate);
    let localEndDate = new Date(endDate);
    if (localStartDate < openDate) {
      width += 25;
      localStartDate = openDate;
      left = true;
    } else {
      position += 25;
    }

    if (localEndDate > closeDate) {
      width += 25;
      localEndDate = closeDate;
      right = true;
    }

    width += ((localEndDate - localStartDate) / 3600000) * hourSize;
    position += ((localStartDate - openDate) / 3600000) * hourSize;

    setStyle({ position, width, left, right });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openDate, closeDate]);

  useEffect(() => {
    if (
      new Date(startDate).getHours() < hours[0] ||
      new Date(startDate).getHours() > hours[hours.length - 1] + 1
    ) {
      console.log(`scheduleItem ${_id} outside of business hours`);
    }
  }, [_id, startDate]);

  const showInfo = () => {
    infoRef.current?.show();
  };

  const infoRef = useRef();

  // Style

  const initialBackgroundColor = hash(scheduleItem.schedule._id, 6);
  const [background, setBackground] = useState(initialBackgroundColor);
  const luma = lightness(initialBackgroundColor);

  const [showBasePriority, setShowBasePriority] = useState(false);

  return (
    <>
      <div
        className="rounded flex-center user-select-none align-items-stretch"
        style={{
          position: "absolute",
          // background: `#${background}`,
          // color: `${luma > 100 ? "black" : "white"}`,
          width: style.width,
          height: "50%",
          left: 0,
          transform: `translate(${style.position}px)`,
          // cursor: "pointer",
          transition: "all 0.2s",
          opacity: status === "pending" ? 1 : 0.4,
        }}

        // onClick={showInfo}
      >
        <div
          style={{
            position: "absolute",
            top: -18,
            fontSize: 12,
            fontWeight: "bold",
            width: "100%",
            zIndex: 1,
          }}
        >
          <div
            className="align-self-center"
            style={{
              transition: "color 0.5s",
              color:
                !priorityModifier || showBasePriority
                  ? "var(--bs-body-color)"
                  : "var(--bs-warning)",
              alignSelf: "center",
            }}
            onMouseEnter={() => {
              setShowBasePriority(true);
            }}
            onMouseLeave={() => {
              setShowBasePriority(false);
            }}
          >
            {priority + (showBasePriority ? 0 : priorityModifier || 0)}
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            bottom: -16,
            fontSize: 12,
            fontWeight: "bold",
            width: "100%",
            zIndex: 1,
          }}
        >
          <div
            style={{
              marginLeft: -50,
              marginRight: -50,
              textAlign: "center",
            }}
          >
            {place + 1}
          </div>
        </div>
        <div className=" btn-group flex-fill flex-center overflow-hidden">
          {style.left && (
            <Button
              className="flex-center h-100"
              style={{ minWidth: 25, maxWidth: 25 }}
              onClick={handlePrevDay}
            >
              <i className="bi bi-chevron-compact-left" />
            </Button>
          )}

          <OverlayTrigger
            trigger="click"
            placement="top"
            rootClose
            overlay={
              <Popover style={{ zIndex: 1000 }}>
                <Popover.Body>
                  <div className="m3-row">
                    {status === "pending" ? (
                      <>
                        <OverlayTrigger
                          placement="top"
                          delay={{ show: 500, hide: 400 }}
                          overlay={
                            <Tooltip>Cambiar prioridad y tiempos</Tooltip>
                          }
                        >
                          <Button
                            variant="primary justify-content-center p-1"
                            onClick={() => handlePriorityChange(scheduleItem)}
                          >
                            <i className="bi bi-arrow-left-right" />
                          </Button>
                        </OverlayTrigger>

                        <OverlayTrigger
                          placement="top"
                          delay={{ show: 500, hide: 400 }}
                          overlay={<Tooltip>Cambiar tiempos</Tooltip>}
                        >
                          <Button
                            variant="primary justify-content-center p-1"
                            onClick={() => handleDatesChange(scheduleItem)}
                          >
                            <i className="bi bi-clock" />
                          </Button>
                        </OverlayTrigger>

                        <OverlayTrigger
                          placement="top"
                          delay={{ show: 500, hide: 400 }}
                          overlay={<Tooltip>Cambiar máquina</Tooltip>}
                        >
                          <Button
                            variant="primary justify-content-center p-1"
                            onClick={() => handleMachineChange(scheduleItem)}
                          >
                            <i className="bi bi-gear-wide-connected" />
                          </Button>
                        </OverlayTrigger>
                      </>
                    ) : (
                      <></>
                    )}

                    <OverlayTrigger
                      placement="top"
                      delay={{ show: 500, hide: 400 }}
                      overlay={<Tooltip>Detalles</Tooltip>}
                    >
                      <Button
                        variant="primary justify-content-center p-1"
                        onClick={showInfo}
                      >
                        <i className="bi bi-eye" />
                      </Button>
                    </OverlayTrigger>
                  </div>
                </Popover.Body>
              </Popover>
            }
          >
            <div
              className="btn position-relative flex-fill flex-center overflow-hidden h-100"
              style={{
                background: `#${background}`,
                color: `${luma > 100 ? "black" : "white"}`,
                lineHeight: "12px",
                textDecoration: status === "pending" ? "" : "line-through",
                opacity: status === "pending" ? 1 : 0.4,
              }}
              onMouseEnter={() =>
                setBackground(mix(initialBackgroundColor, "000000", 90))
              }
              onMouseLeave={() => setBackground(initialBackgroundColor)}
            >
              {name}
            </div>
          </OverlayTrigger>

          {style.right && (
            <Button
              className="flex-center h-100"
              style={{ minWidth: 25, maxWidth: 25 }}
              onClick={handleNextDay}
            >
              <i className="bi bi-chevron-compact-right" />
            </Button>
          )}
        </div>
      </div>

      <ScheduleItemModal ref={infoRef} scheduleItem={scheduleItem} />
    </>
  );
};

const ExternalScheduleItem = ({ scheduleItem }) => {
  const {
    schedule: { priority },
    process: { name, piece },
    startDate,
    endDate,
    place,
    priorityModifier,
    status,
  } = scheduleItem;

  const { handlePriorityChange, handleDatesChange } = useContext(ChartContext);

  const infoRef = useRef();

  // Style

  const initialBackgroundColor = hash(scheduleItem.schedule._id, 6);
  const [background, setBackground] = useState(initialBackgroundColor);
  const luma = lightness(initialBackgroundColor);

  const [showBasePriority, setShowBasePriority] = useState(false);

  return (
    <>
      <div
        className="p-1 fw-bold"
        style={{
          transition: "color 0.5s",
          color:
            !priorityModifier || showBasePriority
              ? "var(--bs-body-color)"
              : "var(--bs-warning)",
        }}
        onMouseEnter={() => {
          setShowBasePriority(true);
        }}
        onMouseLeave={() => {
          setShowBasePriority(false);
        }}
      >
        {priority + (showBasePriority ? 0 : priorityModifier || 0)}
      </div>
      <div
        className="p-1 fw-bold"
        style={
          {
            // background: `#${background}`,
            // color: `${luma > 100 ? "black" : "white"}`,
          }
        }
      >
        {place + 1}
      </div>
      <div
        className="p-1"
        style={{
          background: `#${background}`,
          color: `${luma > 100 ? "black" : "white"}`,
          textDecoration: status === "pending" ? "" : "line-through",
        }}
      >
        {name}
      </div>
      <div
        className="p-1"
        style={{
          background: `#${background}`,
          color: `${luma > 100 ? "black" : "white"}`,
        }}
      >
        {formatDate(startDate)}
      </div>
      <div
        className="p-1"
        style={{
          background: `#${background}`,
          color: `${luma > 100 ? "black" : "white"}`,
        }}
      >
        {formatDate(endDate)}
      </div>
      <div
        className="flex-row flex-center p-1"
        style={{
          background: `#${background}`,
          color: `${luma > 100 ? "black" : "white"}`,
        }}
      >
        <div>{piece.name}</div>
        <div className="flex-fill" />

        <div className="m1-row">
          {status === "pending" ? (
            <>
              <OverlayTrigger
                placement="top"
                delay={{ show: 500, hide: 400 }}
                overlay={<Tooltip>Cambiar prioridad y fechas</Tooltip>}
              >
                <Button
                  variant="primary justify-content-center p-1"
                  onClick={() => handlePriorityChange(scheduleItem)}
                >
                  <i className="bi bi-arrow-left-right" />
                </Button>
              </OverlayTrigger>

              <OverlayTrigger
                placement="top"
                delay={{ show: 500, hide: 400 }}
                overlay={<Tooltip>Cambiar tiempos</Tooltip>}
              >
                <Button
                  variant="primary justify-content-center p-1"
                  onClick={() => handleDatesChange(scheduleItem)}
                >
                  <i className="bi bi-clock" />
                </Button>
              </OverlayTrigger>
            </>
          ) : (
            <></>
          )}

          <OverlayTrigger
            placement="top"
            delay={{ show: 500, hide: 400 }}
            overlay={<Tooltip>Detalles</Tooltip>}
          >
            <Button
              variant="primary justify-content-center p-1"
              onClick={() => infoRef.current?.show()}
            >
              <i className="bi bi-eye" />
            </Button>
          </OverlayTrigger>
        </div>
      </div>
      <ScheduleItemModal ref={infoRef} scheduleItem={scheduleItem} />
    </>
  );
};

export default Chart;
