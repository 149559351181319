import React, { useRef } from "react";
import { useState } from "react";
import { Form } from "react-bootstrap";
import ConfirmModal from "../../components/ConfirmModal";
import IntegerInput from "../../components/IntegerInput";
import ModalForm from "../../components/ModalForm";
import backend from "../../backend";
import { useNavigate } from "react-router-dom";
import { getTimezone } from "../../utils";

const PriorityForm = React.forwardRef(({ scheduleItem }, ref) => {
  const navigate = useNavigate();
  const [priority, setPriority] = useState("");

  const updateForm = () => {
    setPriority(scheduleItem.schedule.priority + scheduleItem.priorityModifier);

    const date =
      new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .split("T")[0] + "T07:00";

    setStartDate(date);

    setRecalculateStartDate(date);
  };

  const confirmRef = useRef();
  const [affected, setAffected] = useState([]);
  const [startDate, setStartDate] = useState();
  const [recalculateStartDate, setRecalculateStartDate] = useState();

  const testSubmit = async () => {
    if (!priority || !startDate || !recalculateStartDate) {
      throw new Error("Faltan campos por completar");
    }
    if (
      priority ===
      scheduleItem.schedule.priority + scheduleItem.priorityModifier
    ) {
      throw new Error("Prioridad es la misma");
    }

    setAffected(
      (
        await backend.get(
          `schedule/${scheduleItem.schedule._id}/affected/${priority}`
        )
      ).data
    );
    await confirmRef.current?.waitConfirm();
  };

  const submit = async () => {
    try {
      await backend.patch("schedule/priority", {
        _id: scheduleItem.schedule._id,
        priority,
        startDate: new Date(startDate),
        recalculateStartDate: new Date(recalculateStartDate),
        scheduleItem: scheduleItem._id,
        timezone: getTimezone(),
      });
      navigate(0);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <ModalForm
        ref={ref}
        title={scheduleItem?.process.piece.name}
        submit={testSubmit}
        onShow={updateForm}
      >
        <div className="m3-col">
          <div>
            <span>
              Prioridad Actual:{" "}
              <span
                className={scheduleItem?.priorityModifier ? "text-warning" : ""}
              >
                {scheduleItem?.schedule.priority +
                  scheduleItem?.priorityModifier}
              </span>
            </span>

            {scheduleItem?.priorityModifier !== 0 && (
              <div>Prioridad Real: {scheduleItem?.schedule.priority}</div>
            )}
          </div>

          <Form.Group>
            Nueva Prioridad:
            <IntegerInput
              className="text-end"
              onChange={e => setPriority(Math.max(e.target.value, 1))}
              value={priority}
              type="number"
              required
              min="1"
            />
          </Form.Group>
          <Form.Group>
            Nueva fecha de inicio:
            <Form.Control
              onChange={e => {
                setStartDate(e.target.value);
                if (priority <= scheduleItem.schedule.priority) {
                  setRecalculateStartDate(e.target.value);
                }
              }}
              value={startDate}
              type="datetime-local"
              required
            />
          </Form.Group>
          <Form.Group>
            Fecha de inicio recálculo:
            <Form.Control
              onChange={e => setRecalculateStartDate(e.target.value)}
              value={recalculateStartDate}
              type="datetime-local"
              required
            />
          </Form.Group>
          <div className="fst-italic text-center">
            Mientras menor el número
            <br />
            mayor es la prioridad
          </div>
        </div>
      </ModalForm>
      <ConfirmModal onAccept={submit} ref={confirmRef}>
        {!!affected.length && (
          <div className="m3-col">
            <div>Las siguientes piezas se van a recalcular</div>
            {affected.map(v => (
              <div key={v._id}>
                <div className="fw-bold">
                  #{v.number} {v.name}
                </div>
                {v.schedules.map(vs => (
                  <div className="ms-3" key={vs._id}>
                    {vs.quantity} {vs.piece.name}
                  </div>
                ))}
              </div>
            ))}
          </div>
        )}
      </ConfirmModal>
    </>
  );
});

export default PriorityForm;
