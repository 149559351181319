import React, { useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CheckBack from "../../components/CheckBack";
import PieceModal from "./PieceModal";

const Piece = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const ref = useRef();

  const goBack = async () => {
    await new Promise(r => setTimeout(r, 100));
    navigate(-1);
  };

  return (
    <CheckBack to="/pieces" onShow={() => ref.current?.show()}>
      <PieceModal ref={ref} pieceId={location.state?._id} onHide={goBack} />
    </CheckBack>
  );
};

export default Piece;
