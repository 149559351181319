import { secret } from "./constants";
import CryptoJS from "crypto-js";

const getAccessToken = () => {
  return getToken("dx");
};

const getRefreshToken = () => {
  return getToken("pk");
};

const getToken = id => {
  let token;
  try {
    const encrypted = localStorage.getItem(id);
    if (!encrypted) {
      throw new Error("Missing data");
    }
    token = CryptoJS.AES.decrypt(encrypted, secret).toString(CryptoJS.enc.Utf8);;
  } catch (err) {
    // console.log(err);
  }
  return token;
};

const setAccessToken = accessToken => {
  setToken(accessToken, "dx");
};

const setRefreshToken = refreshToken => {
  setToken(refreshToken, "pk");
};

const setToken = (token, id) => {
  try {
    let encrypted = CryptoJS.AES.encrypt(token, secret);
    localStorage.setItem(id, encrypted);
  } catch (err) {
    // console.log(err);
  }
};

const intersect = (arrA, arrB) =>
  arrA?.filter(x => arrB.includes(x)).length > 0;

const timePad = date => {
  return date.toString().padStart(2, 0);
};

const clamp = (num, min, max) => Math.min(Math.max(num, min), max);

const ascii_to_hex = (str, length = false) => {
  let arr1 = [];
  for (let n = 0, l = str.length; n < l; n++) {
    let hex = Number(str.charCodeAt(n)).toString(16);
    arr1.push(hex);
  }
  if (length) return arr1.join("").substring(0, length);
  return arr1.join("");
};

function hash(string, length) {
  const result = CryptoJS.SHA256(string);
  return length ? result.slice(0, length) : result;
}

const lightness = hex => {
  var rgb = parseInt(hex, 16); // convert rrggbb to decimal
  var r = (rgb >> 16) & 0xff; // extract red
  var g = (rgb >> 8) & 0xff; // extract green
  var b = (rgb >> 0) & 0xff; // extract blue

  var luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709
  return luma;
};

const formatInt = v => {
  v = `${v}`.replace(/[^0-9]/g, "");
  return v.replaceAll(/^0+(?=\d)/g, "");
};

const formatPrice = v => {
  v = formatInt(v);
  return v.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

// 12:34
const formatTime = date => {
  if (!date) {
    return "Fecha Inválida";
  }
  date = new Date(date);
  return `${timePad(date.getHours())}:${timePad(date.getMinutes())}`;
};

// 17/03/21
const formatDate = date => {
  if (!date) {
    return "Fecha Inválida";
  }
  date = new Date(date);
  return `${date.getDate().toString().padStart(2, "0")}/${(date.getMonth() + 1)
    .toString()
    .padStart(2, "0")}/${date
    .getFullYear()
    .toString()
    .slice(-2)
    .padStart(2, "0")}`;
};

var mix = function (color_1, color_2, weight) {
  function d2h(d) {
    return d.toString(16);
  } // convert a decimal value to hex
  function h2d(h) {
    return parseInt(h, 16);
  } // convert a hex value to decimal

  weight = typeof weight !== "undefined" ? weight : 50; // set the weight to 50%, if that argument is omitted

  var color = "";

  for (var i = 0; i <= 5; i += 2) {
    // loop through each of the 3 hex pairs—red, green, and blue
    var v1 = h2d(color_1.substr(i, 2)), // extract the current pairs
      v2 = h2d(color_2.substr(i, 2)),
      // combine the current pairs from each source color, according to the specified weight
      val = d2h(Math.floor(v2 + (v1 - v2) * (weight / 100.0)));

    while (val.length < 2) {
      val = "0" + val;
    } // prepend a '0' if val results in a single digit

    color += val; // concatenate val to our new color string
  }

  return color; // PROFIT!
};

const getTimezone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

const getLimitedToken = id => {
  try {
    const token = localStorage.getItem(id);
    if (!token) {
      throw new Error("Missing token");
    }
    const { value, date } = JSON.parse(token);
    if (new Date(date) < Date.now()) {
      throw new Error("Token expired");
    } else {
      return value;
    }
  } catch (err) {
    localStorage.removeItem(id);
  }
  return undefined;
};

const setLimitedToken = (id, value, time) => {
  localStorage.setItem(
    id,
    JSON.stringify({ value, date: new Date(Date.now() + time) })
  );
};

const offsetDayDate = (date, offset) => {
  date = new Date(date);
  date.setDate(date.getDate() + offset);
  return date;
};

const getNextDay = date => {
  return offsetDayDate(date, 1);
};

const getPrevDay = date => {
  return offsetDayDate(date, -1);
};

const isWeekend = date => {
  date = new Date(date);
  const day = date.getDay();
  return day === 0 || day === 6;
};

const formatISODate = date => {
  date = new Date(date);
  return new Date(date.getTime() - date.getTimezoneOffset() * 60000)
    .toISOString()
    .split("T")[0];
};

export {
  setAccessToken,
  setRefreshToken,
  getAccessToken,
  getRefreshToken,
  intersect,
  timePad,
  clamp,
  ascii_to_hex,
  hash,
  lightness,
  formatInt,
  formatPrice,
  formatDate,
  formatTime,
  mix,
  getTimezone,
  setLimitedToken,
  getLimitedToken,
  getNextDay,
  getPrevDay,
  isWeekend,
  formatISODate,
};
