import React from "react";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";

const ButtonGroupFilter = ({
  className,
  options = { key: "value" },
  tabState: [tab, setTab],
  buttonClassName = "",
  parseKey = v => v,
}) => {
  return (
    <ButtonGroup className={`align-self-center ${className}`}>
      {Object.entries(options).map(([k, v], i) => (
        <Button
          key={i}
          variant={`${
            tab === parseKey(k) ? "" : "outline-"
          }primary align-items-center border-2 ${buttonClassName}`}
          onClick={() => setTab(parseKey(k))}
        >
          {v}
        </Button>
      ))}
    </ButtonGroup>
  );
};

export default ButtonGroupFilter;
