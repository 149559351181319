import React, { useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import backend from "../../backend";
import CheckBack from "../../components/CheckBack";
import Modal from "../../components/Modal";
import WaitAsync from "../../components/WaitAsync";

const Entity = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const ref = useRef();
  const [entity, setEntity] = useState();

  const getEntity = async () => {
    try {
      setEntity((await backend.get(`entity/${location.state?._id}`)).data);
    } catch (err) {
      console.log(err);
    }
  };

  const getType = type => {
    switch (type) {
      case "client":
        return "Cliente";
      case "provider":
        return "Proveedor";
      default:
        return "";
    }
  };

  const goBack = async () => {
    await new Promise(r => setTimeout(r, 100));
    navigate(-1);
  };

  return (
    <CheckBack to="/entities" onShow={() => ref.current?.show()}>
      <Modal
        ref={ref}
        title={entity?.name || ""}
        hideCancel
        outsideClose
        onHide={goBack}
      >
        <WaitAsync func={getEntity}>
          {entity?.email && <p>{entity?.email}</p>}
          {entity?.phone && <p>{entity?.phone}</p>}
          {entity?.rut && <p>{entity?.rut}</p>}
          {entity?.type && <p>{getType(entity?.type)}</p>}
        </WaitAsync>
      </Modal>
    </CheckBack>
  );
};

export default Entity;
