import React from "react";
import Form from "react-bootstrap/Form";
import { formatInt } from "../utils";

const IntegerInput = ({ onChange = e => {}, ...props }) => {
  return (
    <Form.Control
      onChange={e => {
        e.target.value = formatInt(e.target.value);
        onChange(e);
      }}
      type="number"
      {...props}
      onKeyDown={e => {
        if (/^[e.]$/.test(e.key)) {
          e.preventDefault();
        }
      }}
    />
  );
};

export default IntegerInput;
