import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import backend from "../../backend";
import SearchBar from "../../components/SearchBar";
import WaitAsync from "../../components/WaitAsync";

import ButtonGroupFilter from "../../components/ButtonGroupFilter";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import useTitle from "../../hooks/useTitle";

const Entities = () => {
  useTitle("Contactos");
  const navigate = useNavigate();
  const location = useLocation();

  const [filter, setFilter] = useState("");
  const [tab, setTab] = useState("");

  const [entities, setEntities] = useState([]);

  const getEntities = async () => {
    try {
      setEntities((await backend.get("entity")).data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (location.state?.new) {
      const entity = location.state?.new;
      setEntities(p => [...p, entity]);
      navigate("", { replace: true });
    }
    if (location.state?.edit) {
      const entity = location.state?.edit;
      const updatedEntities = [...entities];
      for (let i = 0; i < updatedEntities.length; ++i) {
        if (updatedEntities[i]._id === entity._id) {
          updatedEntities[i] = entity;
          break;
        }
      }
      setEntities(updatedEntities);
      navigate("", { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <>
      <div>
        <div className=" flex-row align-items-end ">
          <div className="fw-bold fs-1 flex-fill">Contactos</div>
          <Button
            variant="primary"
            className="me-3"
            onClick={() => navigate("new", { state: { back: true } })}
          >
            Agregar
          </Button>
          <SearchBar searchState={[filter, setFilter]} />
        </div>

        <ButtonGroupFilter
          className="mt-3"
          options={{ "": "Todos", client: "Clientes", provider: "Proveedores" }}
          tabState={[tab, setTab]}
          buttonClassName="w-120px"
        />

        <WaitAsync func={getEntities}>
          {entities
            .filter(v => v.name.includes(filter) && (!tab || tab === v.type))
            .map((v, i) => (
              <EntityItem key={i} entity={v} />
            ))}
          {entities.length === 0 ? (
            <div className="fs-5">Actualmente no tienes contactos</div>
          ) : (
            <></>
          )}
        </WaitAsync>
      </div>
      <Outlet />
    </>
  );
};

const EntityItem = ({ entity }) => {
  const navigate = useNavigate();

  const { _id, name, email, type } = entity;

  const getIcon = type => {
    switch (type) {
      case "provider":
        return "truck";
      case "client":
        return "cart";
      default:
        return "question-circle";
    }
  };

  const view = () => navigate("view", { state: { _id, back: true } });
  const edit = () => navigate("edit", { state: { _id, back: true } });
  const archive = () => {};
  return (
    <div className="flex-row mt-3">
      <Card className="flex-row flex-fill py-2 px-3">
        <i className={`bi bi-${getIcon(type)} align-self-center me-2`} />
        <div className="fw-bold">{name}</div>
        <div className="fw-bold ps-3">{email}</div>
      </Card>

      <Button variant="primary justify-content-center ms-1" onClick={view}>
        <i className="bi bi-eye" />
      </Button>
      <Button variant="warning justify-content-center ms-1" onClick={edit}>
        <i className="bi bi-pencil" />
      </Button>
      {process.env.NODE_ENV === "development" && (
        <Button
          variant="secondary justify-content-center ms-1"
          onClick={archive}
        >
          <i className="bi bi-archive" />
        </Button>
      )}
    </div>
  );
};

export default Entities;
