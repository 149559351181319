import Button from "react-bootstrap/Button";

import React, { useRef, useState } from "react";
import SearchBar from "./components/SearchBar";
import WaitAsync from "./components/WaitAsync";
import backend from "./backend";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import AddModal from "./components/ModalForm";
import useTitle from "./hooks/useTitle";

const Purchases = () => {
  useTitle("Compras");
  const [filter, setFilter] = useState("");

  const [purchases, setPurchases] = useState([]);

  const addRef = useRef();

  // Add purchase
  const [name, setName] = useState("");
  const [provider, setProvider] = useState("");
  const [details, setDetails] = useState("");
  const [deliveryOrder, setDeliveryOrder] = useState("");
  const [bill, setBill] = useState("");
  const [total, setTotal] = useState("");

  const getPurchases = async () => {
    try {
      setPurchases((await backend.get("purchaseOrder")).data);
    } catch (err) {
      console.log(err);
    }
  };

  const addPurchase = purchase => {
    setPurchases(p => [purchase, ...p]);
  };

  const addSubmit = async () => {
    if (!name || !provider) {
      throw new Error("Faltan campos por completar");
    }
    //await new Promise(r => setTimeout(r, 5000));
    addPurchase(
      (
        await backend.post("purchaseOrder", {
          name,
          provider,
          details,
          deliveryOrder,
          bill,
          total,
        })
      ).data
    );
  };

  const addReset = () => {
    setName("");
  };

  return (
    <div>
      <div className=" flex-row align-items-end pb-3">
        <div className="fw-bold fs-1 flex-fill">Compras</div>
        <Button
          variant="primary"
          className="me-3"
          onClick={() => addRef.current?.show()}
        >
          Agregar
        </Button>
        <SearchBar searchState={[filter, setFilter]} />
      </div>
      <WaitAsync func={getPurchases}>
        {purchases
          .filter(v => v.name.includes(filter) || v.provider.includes(filter))
          .map((v, i) => (
            <Purchase key={i} purchase={v} />
          ))}
        {purchases.length === 0 ? (
          <div className="fs-5">Actualmente no tienes compras</div>
        ) : (
          <></>
        )}
      </WaitAsync>

      <AddModal
        ref={addRef}
        title="Nueva Compra"
        submit={addSubmit}
        onShow={addReset}
      >
        <Form.Group>
          <Form.Control
            onChange={e => setName(e.target.value)}
            value={name}
            placeholder="Nombre"
            required
          />
        </Form.Group>
        <Form.Group className="mt-3">
          <Form.Control
            onChange={e => setProvider(e.target.value)}
            value={provider}
            placeholder="Proveedor"
            required
          />
        </Form.Group>
        <Form.Group className="mt-3">
          <Form.Control
            onChange={e => setDetails(e.target.value)}
            value={details}
            placeholder="Detalles"
          />
        </Form.Group>
        <Form.Group className="mt-3">
          <Form.Control
            onChange={e => setDeliveryOrder(e.target.value)}
            value={deliveryOrder}
            placeholder="Orden de Entrega"
          />
        </Form.Group>
        <Form.Group className="mt-3">
          <Form.Control
            onChange={e => setBill(e.target.value)}
            value={bill}
            placeholder="Boleta"
          />
        </Form.Group>
        <Form.Group className="mt-3">
          <Form.Control
            onChange={e => setTotal(e.target.value)}
            value={total}
            placeholder="Total"
          />
        </Form.Group>
      </AddModal>
    </div>
  );
};

const Purchase = ({ purchase }) => {
  const { name, details, deliveryOrder, bill, total } = purchase;
  return (
    <div className="flex-row mt-3">
      <Card className="flex-row m3-row flex-fill px-3 py-2">
        <div className="fw-bold">{name}</div>
        <div>{details}</div>
        <div>{deliveryOrder}</div>
        <div>{bill}</div>
        <div className="fw-bold">{`$${total}`}</div>
      </Card>
      <Button variant="primary justify-content-center ms-1">
        <i className="bi bi-eye" />
      </Button>
    </div>
  );
};

export default Purchases;
