import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import { useLocation } from "react-router-dom";
import { clamp, formatInt, formatPrice } from "../utils";
import ButtonAsync from "./ButtonAsync";
import IntegerInput from "./IntegerInput";
import PriceInput from "./PriceInput";

const priceSelectedLabel = {
  price: "General",
  priceField: "Específica Área",
  priceMinimal: "Valor Mínima",
};

// Processes must have machineType populated
const PiecePrice = ({
  piece,
  onProcessPriceSelectedChanged = (i, priceSelected) => {},
  onMaterialPriceChanged = price => {},
  onPriceChanged = price => {},
  onProfitChanged = profit => {},
  updateConstants = async () => {},
  editable,
  style = {},
}) => {
  const location = useLocation();
  const [constantsUpdated, setConstantsUpdated] = useState(false);

  const getTotalPrice = () => {
    if (!piece) {
      return 0;
    }
    let totalPrice = 0;
    for (let i = 0; i < piece.processes.length; ++i) {
      const process = piece.processes[i];
      if (process.external) {
        totalPrice += +formatInt(process.price);
      } else {
        totalPrice += +formatInt(getPrice(i));
      }
    }
    totalPrice += +formatInt(piece.materialPrice);
    return parseInt(totalPrice / (1 - piece.constants.profit / 100));
  };

  const getPrice = i => {
    if (!piece) {
      return 0;
    }
    const process = piece.processes[i];
    if (process.external) {
      return process.price;
    } else {
      if (process.machineType) {
        return Math.round(
          (piece.constants.prices.find(
            p => p.machineType === process.machineType._id
          )[process.priceSelected] *
            process.duration) /
            60
        );
      } else {
        return 0;
      }
    }
  };

  const handleUpdateConstants = async () => {
    await updateConstants();
    setConstantsUpdated(true);
  };

  return (
    <>
      <div
        className="grid"
        style={{
          gridTemplateColumns: "auto auto auto auto",
          columnGap: "12px",
          rowGap: "12px",
          overflowX: "auto",
          paddingTop: "4px",
          ...style,
        }}
      >
        {piece?.processes.map((v, i) => (
          <React.Fragment key={i}>
            {(v.external || v.machineType) && (
              <div
                className="fw-bold"
                style={{ gridColumn: "1 / 5", height: "8px" }}
              >
                {v.name}
              </div>
            )}
            {v.external ? (
              <>
                <div className="flex-row">
                  <i className="bi bi-clock align-self-center pe-1" />
                  <div className="align-self-center">{`${
                    v.duration || 0
                  } d`}</div>
                </div>
                <div className="align-self-center">Proceso Externo</div>
                {/* TODO change this height if priceSelected is not editable */}
                <div style={{ height: editable ? "38px" : "unset" }} />
                <div className="text-end align-self-center">{`$${
                  formatPrice(getPrice(i)) || 0
                }`}</div>
              </>
            ) : v.machineType ? (
              <>
                <div className="flex-row">
                  <i className="bi bi-clock align-self-center pe-1" />
                  <div className="align-self-center">{`${
                    v.duration || 0
                  } m`}</div>
                </div>
                <div className="align-self-center">{v.machineType.type}</div>
                {editable ? (
                  <Form.Group style={{ width: "160px" }}>
                    <Form.Select
                      onChange={e =>
                        onProcessPriceSelectedChanged(i, e.target.value)
                      }
                      value={v.priceSelected}
                      placeholder="Estatus"
                      required
                      className="no-validate"
                    >
                      <option value="price">{priceSelectedLabel.price}</option>
                      <option value="priceField">
                        {priceSelectedLabel.priceField}
                      </option>
                      <option value="priceMinimal">
                        {priceSelectedLabel.priceMinimal}
                      </option>
                    </Form.Select>
                  </Form.Group>
                ) : (
                  <div>{priceSelectedLabel[v.priceSelected]}</div>
                )}
                <div className="text-end align-self-center">{`$${
                  formatPrice(getPrice(i)) || 0
                }`}</div>
              </>
            ) : (
              <></>
            )}
            {(v.external || v.machineType) && (
              <div
                className="border-bottom"
                style={{ gridColumn: "1 / 5", height: "0px" }}
              />
            )}
          </React.Fragment>
        ))}
      </div>
      <div className="flex-row">
        {editable && location.pathname.includes("edit") && (
          <ButtonAsync
            variant="primary m2-row align-self-center"
            onClick={handleUpdateConstants}
            disabled={constantsUpdated}
          >
            <i className="bi bi-cash align-self-center"></i>
            <div>{`${
              constantsUpdated
                ? "Precios actualizados"
                : "Usar precios actuales"
            }`}</div>
          </ButtonAsync>
        )}
        <div
          className="grid flex-fill"
          style={{
            gridTemplateColumns: "1fr auto",
            columnGap: "12px",
            rowGap: "12px",
            overflowX: "auto",
            paddingTop: "4px",
            ...style,
          }}
        >
          <div className="text-end align-self-center">Material</div>
          {editable ? (
            <PriceInput
              onChange={onMaterialPriceChanged}
              value={piece?.materialPrice}
            />
          ) : (
            <div className="text-end">{`$${formatPrice(
              piece?.materialPrice
            )}`}</div>
          )}
          <div className="text-end align-self-center">Utilidad</div>
          {editable ? (
            <Form.Group>
              <IntegerInput
                className="text-end"
                onChange={e => onProfitChanged(clamp(e.target.value, 0, 99))}
                value={piece?.constants?.profit}
                type="number"
                required
                min="0"
                max="99"
              />
            </Form.Group>
          ) : (
            <div className="text-end">{`${piece?.constants.profit}%`}</div>
          )}
          <div className="text-end">Total</div>
          <div className="text-end">{`$${formatPrice(getTotalPrice())}`}</div>
          <div className="text-end align-self-center">Precio Final</div>
          {editable ? (
            <PriceInput
              onChange={onPriceChanged}
              value={piece?.price}
              required
            />
          ) : (
            <div className="text-end">{`$${formatPrice(piece?.price)}`}</div>
          )}
        </div>
      </div>
    </>
  );
};

export default PiecePrice;
