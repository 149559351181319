import React, { useContext, useState } from "react";
import Card from "react-bootstrap/Card";

import AuthContext from "./AuthContext";

import SideBarIcon from "./components/SideBarIcon";

import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";

const SideBar = () => {
  const authContext = useContext(AuthContext);

  return (
    <div
      className="border-end border-dark border-2"
      style={{ overflowY: "auto", minWidth: "77px" }}
    >
      <SideBarIcon icon="house" path="home" name="Inicio" />
      <SideBarIcon icon="list-check" path="orders" name="Pedidos" />
      <SideBarIcon icon="gear-wide-connected" path="machines" name="Máquinas" />
      <SideBarIcon icon="nut" path="pieces" name="Piezas" />
      {process.env.NODE_ENV === "development" && (
        <SideBarIcon icon="boxes" path="inventory" name="Inventario" />
      )}
      {process.env.NODE_ENV === "development" && (
        <SideBarIcon icon="cart" path="purchases" name="Compras" />
      )}
      <SideBarIcon icon="people" path="entities" name="Contactos" />
      <SideBarIcon icon="gear" path="settings" name="Configuración" />
      <div className="flex-fill" />
      <OverlayTrigger
        trigger="click"
        placement="top"
        overlay={
          <Popover>
            <Popover.Body>
              <div className="mb-3 fw-bold" style={{ fontSize: "16px" }}>
                {`${authContext.user?.firstName} ${authContext.user?.lastName}`}
              </div>
              <Button
                variant="primary"
                className="align-self-center"
                onClick={() => authContext.logout()}
              >
                Salir
              </Button>
            </Popover.Body>
          </Popover>
        }
      >
        <div
          className=" p-4 text-white"
          style={{ cursor: "pointer", fontSize: "28px" }}
        >
          <i className="bi bi-person-circle" />
        </div>
      </OverlayTrigger>
    </div>
  );
};

export default SideBar;
