import React, { useRef, useState } from "react";
import Form from "react-bootstrap/Form";
import backend from "../../backend";
import ModalForm from "../../components/ModalForm";
import CheckBack from "../../components/CheckBack";

import { useLocation, useNavigate } from "react-router-dom";

const MachineTypeForm = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const _id = location.state?._id;

  const formRef = useRef();
  const [type, setType] = useState("");
  const [status, setStatus] = useState("");

  const submit = async () => {
    if (!type) {
      throw new Error("Faltan campos por completar");
    }

    if (_id) {
      const machine = (
        await backend.patch("machineType", {
          _id,
          type,
          status,
        })
      ).data;
      navigate("/machines", {
        replace: true,
        state: { edit: machine, type: true },
      });
    } else {
      const machine = (await backend.post("machineType", { type })).data;

      navigate("/machines", {
        replace: true,
        state: { new: machine, type: true },
      });
    }
  };

  const resetForm = () => {
    setType("");
    setStatus("");
  };

  const fillForm = async () => {
    try {
      const data = (await backend.get(`machineType/${_id}`)).data;
      setType(data.type);
      setStatus(data.status);
    } catch (err) {
      console.log(err);
    }
  };

  const goBack = async () => {
    await new Promise(r => setTimeout(r, 100));
    navigate(-1);
  };

  return (
    <CheckBack to="/machines" onShow={() => formRef.current?.show()}>
      <ModalForm
        ref={formRef}
        title={_id ? "Editar Máquina" : "Nuevo Tipo"}
        submit={submit}
        onShow={() => (_id ? fillForm() : resetForm())}
        onHide={goBack}
      >
        <div className="m3-col">
          <Form.Group>
            <Form.Control
              onChange={e => setType(e.target.value)}
              value={type}
              placeholder="Tipo"
              required
            />
          </Form.Group>
          {_id && (
            <Form.Group>
              <Form.Select
                onChange={e => setStatus(e.target.value)}
                value={status}
                placeholder="Estatus"
                required
              >
                <option value="" disabled>
                  Estatus
                </option>
                <option value="active">Activo</option>
                <option value="archived">Archivado</option>
              </Form.Select>
            </Form.Group>
          )}
        </div>
      </ModalForm>
    </CheckBack>
  );
};

export default MachineTypeForm;
