import React, { useContext } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";

import SideBar from "./SideBar";

const Main = () => {
  const location = useLocation();

  return (
    <div className="flex-row h-100">
      <SideBar />
      <div className="flex-fill p-4 overflow-auto">
        <Outlet />
      </div>
    </div>
  );
};

export default Main;
