import React, { useState, useMemo, useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import axios from "axios";

import Login from "./Login";

import { domain } from "./constants";
import { getRefreshToken } from "./utils";
import AuthContext from "./AuthContext";

import "./App.css";
// import Register from "./Register";
// import Pending from "./Pending";
import Main from "./Main";

// import Password from "./Password";
// import Forgot from "./Forgot";
import { authContextRef } from "./backend";
// import Created from "./Created";

import "./custom.scss";
import "bootstrap-icons/font/bootstrap-icons.css";

import Forgot from "./Forgot";
import Pending from "./Pending";
import Password from "./Password";
// eslint-disable-next-line no-unused-vars
import Test from "./Test";
import Orders from "./screens/orders/Orders";
import Pieces from "./screens/pieces/Pieces";
import Inventory from "./Inventory";
import Purchases from "./Purchases";
import Entities from "./screens/entities/Entities";
import Entity from "./screens/entities/Entity";
import EntityForm from "./screens/entities/EntityForm";
import Piece from "./screens/pieces/Piece";
import PieceForm from "./screens/pieces/PieceForm";
import Machine from "./screens/machines/Machine";
import Machines from "./screens/machines/Machines";
import MachineForm from "./screens/machines/MachineForm";
import MachineTypeForm from "./screens/machines/MachineTypeForm";
import QuotationForm from "./screens/orders/QuotationForm";
import Settings from "./screens/settings/Settings";
import PricesForm from "./screens/settings/PricesForm";
import Order from "./screens/orders/Order";
import OrderForm from "./screens/orders/OrderForm";
import Chart from "./screens/chart/Chart";
import OrderFiles from "./screens/orders/OrderFiles";
import DriveForm from "./screens/settings/DriveForm";

const App = () => {
  const [logged, setLogged] = useState(false);

  const authContext = useMemo(
    () => ({
      login: () => {
        setLogged(true);
      },
      logout: async () => {
        // console.log("logout pressed");

        try {
          const refreshToken = getRefreshToken();

          await axios.post(`${domain}/api/user/logout`, { refreshToken });
          // console.log("logout");
        } catch (err) {
          // console.log("authContext logout", err);
          console.log(err);
        }

        localStorage.clear();
        setLogged(false);
      },
      signUp: async data => {
        console.log("meh");
      },
      user: null, // to fill on login
    }),

    []
  );

  useEffect(() => {
    authContextRef.current = authContext;
  }, [authContext]);

  return (
    <>
      <AuthContext.Provider value={authContext}>
        <BrowserRouter>
          <Routes>
            <Route path="/">
              <Route path="pending" element={<Pending />} />
              {/* <Route exact path="/created" component={Created} /> */}
              <Route path="forgot" element={<Forgot />} />
              <Route path="password/:token" element={<Password />} />
              {logged ? (
                <>
                  <Route
                    path="error"
                    element={<div style={{ color: "black" }}>error</div>}
                  />
                  <Route path="/" element={<Main />}>
                    <Route path="home" element={<Chart />} />
                    <Route path="orders" element={<Orders />}>
                      <Route path="view" element={<Order />} />
                      <Route path="files" element={<OrderFiles />} />
                      <Route path="edit" element={<OrderForm />} />
                    </Route>
                    <Route
                      path="orders/quotation/new"
                      element={<QuotationForm />}
                    />

                    <Route path="machines" element={<Machines />}>
                      <Route path="view" element={<Machine />} />
                      <Route path="new" element={<MachineForm />} />
                      <Route path="new_type" element={<MachineTypeForm />} />
                      <Route path="edit" element={<MachineForm />} />
                      <Route path="edit_type" element={<MachineTypeForm />} />
                    </Route>
                    <Route path="pieces" element={<Pieces />}>
                      <Route path="view" element={<Piece />} />
                    </Route>
                    <Route path="pieces/new" element={<PieceForm />} />
                    <Route path="pieces/edit" element={<PieceForm />} />

                    <Route path="inventory" element={<Inventory />} />
                    <Route path="purchases" element={<Purchases />} />
                    <Route path="entities" element={<Entities />}>
                      <Route path="view" element={<Entity />} />
                      <Route path="new" element={<EntityForm />} />
                      <Route path="edit" element={<EntityForm />} />
                    </Route>
                    <Route path="settings" element={<Settings />}>
                      <Route path="prices" element={<PricesForm />} />
                      <Route path="drive" element={<DriveForm />} />
                    </Route>
                    <Route path="/" element={<Navigate to="home" replace />} />
                  </Route>
                </>
              ) : (
                <>
                  <Route path="/" element={<Login />} />
                  <Route path="*" element={<Login />} />
                </>
              )}
            </Route>
          </Routes>
        </BrowserRouter>
      </AuthContext.Provider>
    </>
  );
};

export default App;
