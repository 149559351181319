import React, { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Form } from "react-bootstrap";
import ConfirmModal from "../../components/ConfirmModal";
import ModalForm from "../../components/ModalForm";
import backend from "../../backend";
import { useNavigate } from "react-router-dom";
import { getTimezone } from "../../utils";

const DatesForm = React.forwardRef(({ scheduleItem }, ref) => {
  const navigate = useNavigate();

  const updateForm = () => {
    if (scheduleItem) {
      const sd = new Date(
        new Date(scheduleItem.startDate).getTime() -
          new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .slice(0, -8);
      setStartDate(sd);
      setCurrentStartDate(sd);
      setEndDate(
        new Date(
          new Date(scheduleItem.endDate).getTime() -
            new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .slice(0, -8)
      );
    }
  };

  const confirmRef = useRef();
  const [affected, setAffected] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [currentStartDate, setCurrentStartDate] = useState();

  const testSubmit = async () => {
    if (!startDate || !endDate) {
      throw new Error("Faltan campos por completar");
    }
    if (
      scheduleItem.place &&
      new Date(startDate) < new Date(currentStartDate)
    ) {
      throw new Error("Inicio debe ser mayor o igual a actual");
    }
    if (new Date(startDate) >= new Date(endDate)) {
      throw new Error("Inicio debe ser menor a término");
    }

    // setAffected(
    //   (
    //     await backend.get(
    //       `schedule/${scheduleItem.schedule._id}/affected/${priority}`
    //     )
    //   ).data
    // );
    await confirmRef.current?.waitConfirm();
  };

  const submit = async () => {
    try {
      await backend.patch("schedule/dates", {
        _id: scheduleItem.schedule._id,
        startDate: new Date(startDate),
        endDate: new Date(endDate),
        scheduleItem: scheduleItem._id,
        timezone: getTimezone(),
      });
      navigate(0);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <ModalForm
        ref={ref}
        title={scheduleItem?.process.piece.name}
        submit={testSubmit}
        onShow={updateForm}
      >
        <div className="m3-col">
          <Form.Group>
            Fecha de inicio:
            <Form.Control
              onChange={e => setStartDate(e.target.value)}
              value={startDate}
              type="datetime-local"
              required
            />
          </Form.Group>
          <Form.Group>
            Fecha de término:
            <Form.Control
              onChange={e => setEndDate(e.target.value)}
              value={endDate}
              type="datetime-local"
              required
            />
          </Form.Group>
          {scheduleItem?.place > 0 && (
            <div className="fst-italic text-center">
              La fecha de inicio debe ser
              <br />
              mayor o igual a la actual
            </div>
          )}
        </div>
      </ModalForm>
      <ConfirmModal onAccept={submit} ref={confirmRef}>
        {!!affected.length && (
          <div className="m3-col">
            <div>Las siguientes piezas se van a recalcular</div>
            {affected.map(v => (
              <div key={v._id}>
                <div className="fw-bold">
                  #{v.number} {v.name}
                </div>
                {v.schedules.map(vs => (
                  <div className="ms-3" key={vs._id}>
                    {vs.quantity} {vs.piece.name}
                  </div>
                ))}
              </div>
            ))}
          </div>
        )}
      </ConfirmModal>
    </>
  );
});

export default DatesForm;
