import React from "react";
import Form from "react-bootstrap/Form";
import CloseButton from "react-bootstrap/CloseButton";

const SearchBar = ({ searchState: [search, setSearch] }) => {
  return (
    <div className="position-relative">
      <Form.Control
        className="ph-38px"
        onChange={e => setSearch(e.target.value)}
        value={search}
      />
      <div className="position-absolute justify-content-center s-38px">
        <i className="bi bi-search align-self-center" />
      </div>
      {search !== "" ? (
        <div
          className="position-absolute justify-content-center s-38px end-0
    "
        >
          <CloseButton
            className="align-self-center"
            onClick={() => setSearch("")}
          />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default SearchBar;
