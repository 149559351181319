import React, { useRef, useState } from "react";
import Form from "react-bootstrap/Form";

import backend from "../../backend";
import ModalForm from "../../components/ModalForm";
import CheckBack from "../../components/CheckBack";

import { useNavigate } from "react-router-dom";

const PricesForm = () => {
  const navigate = useNavigate();

  const formRef = useRef();

  const [prices, setPrices] = useState([]);

  const submit = async () => {
    for (const price of prices) {
      if (
        price.price === "" ||
        price.priceField === "" ||
        price.priceMinimal === ""
      ) {
        throw new Error("Faltan campos por completar");
      }
    }
    await backend.patch("constants/prices", {
      prices,
    });
  };

  const getPrices = async () => {
    try {
      const constants = (await backend.get("constants/machineType")).data;
      setPrices(constants.prices);
    } catch (err) {
      console.log(err);
    }
  };

  const setValue = (k, v, i) => {
    const newPrices = [...prices];
    newPrices[i][k] = v;
    setPrices(newPrices);
  };

  const goBack = async () => {
    await new Promise(r => setTimeout(r, 100));
    navigate(-1);
  };

  const setNumber = (v, set = v => {}) => {
    v = v.replace(/[^0-9]/g, "");
    set(v);
  };

  return (
    <CheckBack to="/settings" onShow={() => formRef.current?.show()}>
      <ModalForm
        ref={formRef}
        title={"Precios"}
        submit={submit}
        onShow={getPrices}
        onHide={goBack}
        size="xl"
      >
        <div
          className="grid"
          style={{
            gridTemplateColumns: "auto 200px 200px 200px",
            columnGap: "12px",
            rowGap: "12px",
            overflowX: "auto",
          }}
        >
          <div />
          <div>General</div>
          <div>Area Específica</div>
          <div>Valor Mínima</div>

          {prices.map((v, i) => (
            <React.Fragment key={i}>
              <div>{v.machineType?.type}</div>

              <Form.Group>
                <Form.Control
                  onChange={e =>
                    setNumber(e.target.value, p => setValue("price", p, i))
                  }
                  value={v.price}
                  required
                />
              </Form.Group>
              <Form.Group>
                <Form.Control
                  onChange={e =>
                    setNumber(e.target.value, p => setValue("priceField", p, i))
                  }
                  value={v.priceField}
                  required
                />
              </Form.Group>
              <Form.Group>
                <Form.Control
                  onChange={e =>
                    setNumber(e.target.value, p =>
                      setValue("priceMinimal", p, i)
                    )
                  }
                  value={v.priceMinimal}
                  required
                />
              </Form.Group>
            </React.Fragment>
          ))}
        </div>
      </ModalForm>
    </CheckBack>
  );
};

export default PricesForm;
