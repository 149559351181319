import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";

import backend from "../../backend";
import SearchBar from "../../components/SearchBar";
import WaitAsync from "../../components/WaitAsync";
import ButtonGroupFilter from "../../components/ButtonGroupFilter";

import useTitle from "../../hooks/useTitle";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

const Machines = () => {
  useTitle("Máquinas");
  const navigate = useNavigate();
  const location = useLocation();

  const [filter, setFilter] = useState("");
  const [tab, setTab] = useState("");

  const [machines, setMachines] = useState([]);
  const [machineTypes, setMachineTypes] = useState([]);

  const getMachines = async () => {
    try {
      setMachines((await backend.get("machine")).data);
    } catch (err) {
      console.log(err);
    }
  };

  const getMachineTypes = async () => {
    try {
      setMachineTypes((await backend.get("machineType")).data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (location.state?.type) {
      if (location.state?.new) {
        const machine = location.state?.new;
        setMachineTypes(p => [...p, machine]);
        navigate("", { replace: true });
      }
      if (location.state?.edit) {
        const machine = location.state?.edit;
        const updatedMachines = [...machineTypes];
        for (let i = 0; i < updatedMachines.length; ++i) {
          if (updatedMachines[i]._id === machine._id) {
            updatedMachines[i] = machine;
            break;
          }
        }
        setMachineTypes(updatedMachines);
        navigate("", { replace: true });
      }
    } else {
      if (location.state?.new) {
        const machine = location.state?.new;
        setMachines(p => [...p, machine]);
        navigate("", { replace: true });
      }
      if (location.state?.edit) {
        const machine = location.state?.edit;
        const updatedMachines = [...machines];
        for (let i = 0; i < updatedMachines.length; ++i) {
          if (updatedMachines[i]._id === machine._id) {
            updatedMachines[i] = machine;
            break;
          }
        }
        setMachines(updatedMachines);
        navigate("", { replace: true });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <>
      <div>
        <div className=" flex-row align-items-end ">
          <div className="fw-bold fs-1 flex-fill">Máquinas</div>
          <Button
            variant="primary"
            className="me-3"
            onClick={() => navigate("new", { state: { back: true } })}
          >
            Agregar
          </Button>
          <Button
            variant="primary"
            className="me-3"
            onClick={() => navigate("new_type", { state: { back: true } })}
          >
            Agregar Tipo
          </Button>
          <SearchBar searchState={[filter, setFilter]} />
        </div>
        <ButtonGroupFilter
          className="mt-3"
          options={{ "": "Todos", active: "Activas", inactive: "Inactivas" }}
          tabState={[tab, setTab]}
          buttonClassName="w-120px"
        />
        <WaitAsync func={getMachines}>
          {machines
            .filter(v => v.name.includes(filter) && (!tab || tab === v.status))
            .map((v, i) => (
              <MachineItem key={i} machine={v} />
            ))}
          {machines.length === 0 ? (
            <div className="fs-5">Actualmente no tienes máquinas</div>
          ) : (
            <></>
          )}
        </WaitAsync>
        <WaitAsync func={getMachineTypes}>
          <div
            className=" flex-row align-items-end mt-5"
            style={{ marginTop: "50px" }}
          >
            <div className="fw-bold fs-5 flex-fill">Tipos de Máquinas</div>
          </div>
          {machineTypes
            .filter(v => v.status !== "archived")
            .map((v, i) => (
              <MachineTypeItem key={i} machineType={v} />
            ))}
          {machineTypes.length === 0 ? (
            <div className="fs-5">Actualmente no tienes tipos de máquinas</div>
          ) : (
            <></>
          )}
        </WaitAsync>
      </div>
      <Outlet />
    </>
  );
};

const MachineItem = ({ machine }) => {
  const navigate = useNavigate();

  const { _id, name, category, status, type } = machine;

  const getIcon = type => {
    switch (type) {
      case "active":
        return "lightbulb";
      case "inactive":
        return "lightbulb-off";
      default:
        return "question-circle";
    }
  };

  // eslint-disable-next-line no-unused-vars
  const view = () => navigate("view", { state: { _id, back: true } });
  const edit = () => navigate("edit", { state: { _id, back: true } });
  const archive = () => {};
  return (
    <div className="flex-row mt-3">
      <Card className="flex-row flex-fill py-2 px-3">
        <i className={`bi bi-${getIcon(status)} align-self-center me-2`} />
        <div className="fw-bold">{`${type.type} - ${name}`}</div>
        <div className="fw-bold ps-3">{category}</div>
      </Card>

      {/* <Button variant="primary justify-content-center ms-1" onClick={view}>
        <i className="bi bi-eye" />
      </Button> */}
      <Button variant="warning justify-content-center ms-1" onClick={edit}>
        <i className="bi bi-pencil" />
      </Button>
      {process.env.NODE_ENV === "development" && (
        <Button
          variant="secondary justify-content-center ms-1"
          onClick={archive}
        >
          <i className="bi bi-archive" />
        </Button>
      )}
    </div>
  );
};
const MachineTypeItem = ({ machineType }) => {
  const navigate = useNavigate();

  const { _id, type } = machineType;

  const edit_type = () => navigate("edit_type", { state: { _id, back: true } });
  const archive_type = () => {};
  return (
    <div className="flex-row mt-3">
      <Card className="flex-row flex-fill py-2 px-3">
        <div className="fw-bold">{type}</div>
      </Card>
      <Button variant="warning justify-content-center ms-1" onClick={edit_type}>
        <i className="bi bi-pencil" />
      </Button>
      {process.env.NODE_ENV === "development" && (
        <Button
          variant="secondary justify-content-center ms-1"
          onClick={archive_type}
        >
          <i className="bi bi-archive" />
        </Button>
      )}
    </div>
  );
};

export default Machines;
