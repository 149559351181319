import React from "react";
import useTitle from "./hooks/useTitle";

const Inventory = () => {
  useTitle("Inventario");
  return (
    <div>
      <p>Inventory</p>
    </div>
  );
};

export default Inventory;
