import React, { useState, useContext, useEffect } from "react";

import axios from "axios";

// import "./Login.css";

// import { Error, Loading } from "./Components";
import { domain } from "./constants";
import { getRefreshToken, setRefreshToken } from "./utils";
import AuthContext from "./AuthContext";
import { Link } from "react-router-dom";

import validator from "validator";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import Loading from "./components/Loading";

import logo from "./logo.jpg";

const Login = () => {
  useEffect(() => {
    document.title = "Ingreso";
  }, []);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [validated, setValidated] = useState(false);
  const [error, setError] = useState("");

  const [hasCapsLock, setHasCapsLock] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  const authContext = useContext(AuthContext);

  const checkLogged = async () => {
    const refreshToken = getRefreshToken();
    try {
      // await new Promise(t => setTimeout(t, 1000));

      if (refreshToken) {
        let response = await axios.post(
          `${domain}/api/user/logged`,
          {
            refreshToken,
            userAgent: navigator.userAgent,
          },
          { timeout: 5000 }
        );
        authContext.user = response.data;
        authContext.login();
      } else {
        setIsLoading(false);
        throw new Error("Not logged");
      }
    } catch (err) {
      setIsLoading(false);
      // console.log(err);
      // localStorage.clear();
    }
  };

  useEffect(() => {
    checkLogged();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submit = async () => {
    setValidated(false);
    setError(undefined);

    try {
      if (!email || !password) {
        setError("Faltan campos por completar");
        setValidated(true);
        return;
      }

      if (email && !validator.isEmail(email)) {
        setError("Correo no válido");
        setValidated(true);
        return;
      }

      setIsLoading(true);

      let response = await axios.post(`${domain}/api/user/login`, {
        email,
        password,
      });
      let token = response.data.token;
      let userAgent = navigator.userAgent;

      response = await axios.post(`${domain}/api/user/validate`, {
        userAgent,
        token,
      });

      authContext.user = response.data.user;
      const refreshToken = response.data.refreshToken;

      setRefreshToken(refreshToken);

      authContext.login();
    } catch (err) {
      console.log(err);
      setIsLoading(false);
      setError("Correo o contraseña inválidos");
    }

    setValidated(true);
  };

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div style={{ height: "20vh" }} />

          <Card
            className="align-self-center"
            style={{ minWidth: "350px" }}
            bg="dark"
          >
            <Card.Body>
              <img
                src={logo}
                className="align-self-center mb-3"
                alt="Logo"
                style={{ width: "200px", borderRadius: "4px" }}
              />
              <Form
                noValidate
                validated={validated}
                onSubmit={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  submit();
                }}
              >
                <Form.Group className="mb-3">
                  <Form.Control
                    onChange={e => setEmail(e.target.value)}
                    value={email}
                    placeholder="Correo electrónico"
                    type="email"
                    name="email"
                    id="email"
                    autoComplete="email"
                    required
                  />
                </Form.Group>
                {hasCapsLock && (
                  <div className="invalid-feedback mt-0">
                    Tienes las mayúsculas activas
                  </div>
                )}
                <div className="position-relative">
                  <Form.Group>
                    <Form.Control
                      onChange={e => setPassword(e.target.value)}
                      onKeyDown={e => {
                        setHasCapsLock(e.getModifierState("CapsLock"));
                        if (e.key === "Enter") {
                          submit();
                        }
                      }}
                      value={password}
                      placeholder="Contraseña"
                      type="password"
                      name="password"
                      id="password"
                      autoComplete="current-password"
                      autoCapitalize="off"
                      required
                    />
                  </Form.Group>
                  <Link
                    to={{ pathname: "/forgot", state: { back: true } }}
                    className="position-absolute text-decoration-none text-muted"
                    style={{ right: "9px", bottom: "7px" }}
                  >
                    olvidaste tu contraseña?
                  </Link>
                </div>

                {error && <div className="invalid-feedback mb-3">{error}</div>}

                <Button
                  variant="primary"
                  className="align-self-center mt-3"
                  type="submit"
                >
                  Acceder
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </>
      )}
    </>
  );
};

export default Login;
