import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";

import backend from "../../backend";

import SearchBar from "../../components/SearchBar";
import WaitAsync from "../../components/WaitAsync";

import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import useTitle from "../../hooks/useTitle";

const Pieces = () => {
  useTitle("Piezas");
  const navigate = useNavigate();
  const location = useLocation();

  const [filter, setFilter] = useState("");

  const [pieces, setPieces] = useState([]);

  const getPieces = async () => {
    try {
      setPieces((await backend.get("piece")).data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (location.state?.new) {
      const piece = location.state?.new;
      setPieces(p => [...p, piece]);
      navigate("", { replace: true });
    }
    if (location.state?.edit) {
      const piece = location.state?.edit;
      const updatedPieces = [...pieces];
      for (let i = 0; i < updatedPieces.length; ++i) {
        if (updatedPieces[i]._id === piece._id) {
          updatedPieces[i] = piece;
          break;
        }
      }
      setPieces(updatedPieces);
      navigate("", { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <>
      <div>
        <div className=" flex-row m3-row align-items-end pb-3">
          <div className="fw-bold fs-1 flex-fill">Piezas</div>
          <Link to="new" className="btn btn-primary">
            Agregar
          </Link>
          <SearchBar searchState={[filter, setFilter]} />
        </div>

        <WaitAsync func={getPieces}>
          {pieces
            .filter(v => v.name.includes(filter) || v.sku.includes(filter))
            .map((v, i) => (
              <Piece key={i} piece={v} />
            ))}
          {pieces.length === 0 ? (
            <div className="fs-5">Actualmente no tienes piezas</div>
          ) : (
            <></>
          )}
        </WaitAsync>
      </div>
      <Outlet />
    </>
  );
};

const Piece = ({ piece }) => {
  const navigate = useNavigate();
  const { _id, name, sku, details } = piece;

  const view = () => navigate("view", { state: { _id, back: true } });
  const edit = () => navigate("edit", { state: { _id, back: true } });
  const archive = () => {};
  return (
    <div className="flex-row mt-3">
      <Card className="flex-row m3-row flex-fill py-2 px-3">
        <div className="fw-bold">{sku}</div>
        <div className="fw-bold">{name}</div>
        <div className="fw-bold">{details}</div>
      </Card>
      <Button variant="primary justify-content-center ms-1" onClick={view}>
        <i className="bi bi-eye" />
      </Button>
      <Button variant="warning justify-content-center ms-1" onClick={edit}>
        <i className="bi bi-pencil" />
      </Button>
      {process.env.NODE_ENV === "development" && (
        <Button
          variant="secondary justify-content-center ms-1"
          onClick={archive}
        >
          <i className="bi bi-archive" />
        </Button>
      )}
    </div>
  );
};

export default Pieces;
