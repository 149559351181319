import React from "react";
import Button from "react-bootstrap/Button";
import { Outlet, useNavigate } from "react-router-dom";
import useTitle from "../../hooks/useTitle";

const Settings = () => {
  useTitle("Configuración");
  const navigate = useNavigate();
  return (
    <>
      <div className="m3-col">
        <div className="fw-bold fs-1">Configuración</div>
        <Button
          variant="primary"
          onClick={() => navigate("prices", { state: { back: true } })}
        >
          Ajustar precios
        </Button>
        <Button
          variant="primary"
          onClick={() => navigate("drive", { state: { back: true } })}
        >
          Google Drive
        </Button>
      </div>
      <Outlet />
    </>
  );
};

export default Settings;
