import React, { useRef, useState } from "react";
import { Button } from "react-bootstrap";
import Modal from "../../components/Modal";
import PieceModal from "../pieces/PieceModal";
import { formatDate, formatTime } from "../../utils";
import ModalForm from "../../components/ModalForm";
import backend from "../../backend";
import { useNavigate } from "react-router-dom";

const ScheduleItemModal = React.forwardRef(({ scheduleItem }, ref) => {
  const {
    process: { name, piece },
    startDate,
    endDate,
    schedule: { quantity, priority, order },
    place,
    status,
  } = scheduleItem;

  const pieceRef = useRef();
  const navigate = useNavigate();
  const [reload, setReload] = useState(false);

  const getStatus = status => {
    switch (status) {
      case "pending":
        return "Pendiente";
      case "completed":
        return "Completado";
      default:
        return "?";
    }
  };

  const finishScheduleItem = async () => {
    await backend.patch("scheduleItem/finish", {
      _id: scheduleItem._id,
    });

    await new Promise(r => setTimeout(r, 100));
    navigate(0);
  };

  return (
    <ModalForm
      ref={ref}
      title={name}
      hideCancel
      outsideClose
      hideAccept={!status || status === "completed"}
      submit={async () => {
        await finishScheduleItem();
      }}
      acceptText="Finalizar Proceso"
      loadOnAccept
    >
      <div
        className="grid"
        style={{ gridTemplateColumns: "auto auto", gap: 16 }}
      >
        <div>Inicio:</div>
        <div>
          {formatTime(startDate)} {formatDate(startDate)}
        </div>
        <div>Término:</div>
        <div>
          {formatTime(endDate)} {formatDate(endDate)}
        </div>

        <div>Pieza:</div>
        <div className="m2-row">
          <div>{piece?.name}</div>
          <Button
            variant="primary justify-content-center p-1"
            onClick={() => pieceRef.current?.show()}
          >
            <i className="bi bi-eye" />
          </Button>
        </div>
        <div>Cantidad:</div>
        <div>{quantity}</div>
        <div>Prioridad:</div>
        <div>{priority}</div>
        <div>Posición:</div>
        <div>{place + 1}</div>
        <div>Orden:</div>
        <div>
          #{order.number} {order.name}
        </div>
        <div>Estado:</div>
        <div>{getStatus(status)}</div>
      </div>

      <PieceModal pieceId={piece?._id} ref={pieceRef} />
    </ModalForm>
  );
});

export default ScheduleItemModal;
