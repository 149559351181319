import React, { useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";

import backend from "../../backend";
import CheckBack from "../../components/CheckBack";
import WaitAsync from "../../components/WaitAsync";
import PieceModal from "../pieces/PieceModal";

import { Form } from "react-bootstrap";
import { getTimezone } from "../../utils";
import ModalForm from "../../components/ModalForm";

const Order = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const ref = useRef();
  const [order, setOrder] = useState();
  const [startDate, setStartDate] = useState(
    new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .split("T")[0] + "T07:00"
  );
  const pieceRef = useRef();
  const [pieceId, setPieceId] = useState("");

  // TODO: temp, must be replaced
  const [reload, setReload] = useState(false);

  const getOrder = async () => {
    try {
      setOrder((await backend.get(`order/${location.state?._id}`)).data);
    } catch (err) {
      console.log(err);
    }
  };

  const goBack = async () => {
    await new Promise(r => setTimeout(r, 100));
    navigate(-1);
  };

  const createSchedule = async () => {
    if (!order) {
      throw new Error("Orden no ha cargado");
    }

    if (!startDate) {
      throw new Error("Fecha de inicio no ingresada");
    }

    const milliTime =
      parseInt(startDate.split("T")[1].split(":")[0]) * 60 * 60 * 1000 +
      parseInt(startDate.split("T")[1].split(":")[1]) * 60000;
    if (milliTime < 7 * 60 * 60 * 1000 || milliTime > 16 * 60 * 60 * 1000) {
      throw new Error("Hora de inicio no válida");
    }

    const workingOrder = await backend.patch("order/work", {
      _id: order._id,
      startDate: new Date(startDate),
      timezone: getTimezone(),
    }).data;

    navigate("/orders", {
      replace: true,
      state: { working: workingOrder },
    });

    setReload(true);
  };

  const finishOrder = async () => {
    const finishedOrder = (
      await backend.patch("order/finish", {
        _id: order._id,
      })
    ).data;
    await new Promise(r => setTimeout(r, 100));
    navigate("/orders", {
      replace: true,
      state: { finish: finishedOrder },
    });
    setReload(true);
  };

  return (
    <CheckBack to="/orders" onShow={() => ref.current?.show()}>
      <ModalForm
        ref={ref}
        title={order?.name || ""}
        hideCancel
        hideAccept={!order || order?.status === "completed"}
        submit={async () => {
          if (order?.status === "created") {
            await createSchedule();
          } else if (order?.status === "working") {
            await finishOrder();
          }
        }}
        onHide={goBack}
        acceptText={
          order?.status === "created" ? "Empezar Orden" : "Finalizar Orden"
        }
        loadOnAccept
      >
        <WaitAsync func={getOrder}>
          <div className="m3-col">
            {order?.name && order?.number && (
              <div>
                #{order?.number} {order?.name}
              </div>
            )}
            {order?.client && (
              <div>
                Cliente: {order?.client.name} {order?.client.rut}
              </div>
            )}
            <div className="m1-col">
              <div>Piezas:</div>
              {order?.pieces?.map((v, i) => (
                <React.Fragment key={i}>
                  <div className="m3-row">
                    <div className="flex-fill">
                      ({v.quantity}) {v.piece.name}{" "}
                    </div>
                    <Button
                      variant="primary justify-content-center p-1"
                      onClick={() => {
                        setPieceId(v.piece._id);
                        pieceRef.current?.show();
                      }}
                    >
                      <i className="bi bi-eye" />
                    </Button>
                  </div>
                </React.Fragment>
              ))}
            </div>

            <PieceModal pieceId={pieceId} ref={pieceRef} />

            {order?.status === "created" ? (
              <>
                <Form.Group>
                  Fecha de inicio:
                  <Form.Control
                    onChange={e => setStartDate(e.target.value)}
                    value={startDate}
                    type="datetime-local"
                    required
                  />
                </Form.Group>
              </>
            ) : (
              <></>
            )}

            {/* {order?.status && <p>{getStatus(order?.status)}</p>} */}
          </div>
        </WaitAsync>
      </ModalForm>
    </CheckBack>
  );
};

export default Order;
