import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
// import { Error } from "./Components";
import { domain } from "./constants";
import validator from "validator";

import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

const Forgot = () => {
  useEffect(() => {
    document.title = "Recuperar Contraseña";
  }, []);

  const navigate = useNavigate();
  const location = useLocation();

  const [email, setEmail] = useState("");

  const [validated, setValidated] = useState(false);
  const [error, setError] = useState("");

  const ref = useRef();

  const back = () =>
    location.state?.back ? navigate(-1) : navigate("/", { replace: true });

  const submit = async () => {
    try {
      if (!email) {
        setError("Faltan campos por completar");
        setValidated(true);
        return;
      } else if (!validator.isEmail(email)) {
        setError("Correo no válido");
        setValidated(true);
        return;
      }

      await axios.post(`${domain}/api/user/forgot`, { email });
      navigate("/pending", { state: { back: true } });
    } catch (err) {
      console.log(err);

      let data = err.response?.data;
      if (data === "user missing") {
        setError("Usuario no registrado");
        ref.current.show();
      }
    }
  };

  return (
    <>
      <div style={{ height: "20vh" }} />
      <Card className="align-self-center" style={{ minWidth: "350px" }}>
        <Card.Body>
          <div className="fw-bold text-center fs-3 mb-3">
            Recuperar Contraseña
          </div>
          <Form
            noValidate
            validated={validated}
            onSubmit={e => {
              e.preventDefault();
              e.stopPropagation();
              submit();
            }}
          >
            <Form.Group>
              <Form.Control
                onChange={e => setEmail(e.target.value)}
                value={email}
                placeholder="Correo electrónico"
                type="email"
                name="email"
                id="email"
                autoComplete="email"
                required
              />
            </Form.Group>

            {error && <div className="invalid-feedback">{error}</div>}

            <div className="flex-row align-self-center mt-3">
              <Button
                variant="secondary"
                className="align-self-center"
                onClick={back}
              >
                Cancelar
              </Button>
              <div className="me-3" />
              <Button
                variant="primary"
                className="align-self-center"
                onClick={submit}
              >
                Acceder
              </Button>
            </div>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};

export default Forgot;
