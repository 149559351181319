import React, { useRef, useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Select from "react-select";
import { useNavigate, useNavigationType } from "react-router-dom";
import backend from "../../backend";
import WaitAsync from "../../components/WaitAsync";
import PriceInput from "../../components/PriceInput";
import IntegerInput from "../../components/IntegerInput";
import { formatInt, formatPrice } from "../../utils";
import PieceModal from "../pieces/PieceModal";

const QuotationForm = () => {
  const navigate = useNavigate();
  const navigationType = useNavigationType();

  const getNewItem = () => ({
    quantity: 1,
    piece: null,
    price: "0",
  });

  const [pieces, setPieces] = useState([]);

  // {quantity, piece, price}
  const [items, setItems] = useState([getNewItem()]);
  const [name, setName] = useState("");
  const [client, setClient] = useState("");
  const [clients, setClients] = useState("");

  const [pieceId, setPieceId] = useState("");
  const ref = useRef();

  const [validated, setValidated] = useState(false);
  const [error, setError] = useState();

  const waitRef = useRef();

  const getData = async () => {
    try {
      const piecesData = (await backend.get("piece")).data;
      setPieces(
        piecesData.map(v => ({
          ...v,
          value: v._id,
          label: `${v.sku} - ${v.name}`,
        }))
      );

      let clientsData = (await backend.get("entity")).data;
      clientsData = clientsData.filter(v => v.type === "client");
      setClients(
        clientsData.map(v => ({
          ...v,
          value: v._id,
          label: `${v.rut} - ${v.name}`,
        }))
      );
    } catch (err) {
      console.log(err);
    }
  };

  const cancel = () =>
    navigationType === "POP"
      ? navigate("/orders", { replace: true })
      : navigate(-1);

  const submit = async () => {
    setValidated(false);
    try {
      waitRef.current?.setLoading(true);
      setError();
      if (!name) {
        throw new Error("Faltan campos por completar");
      }
      if (!client) {
        throw new Error("Faltan campos por completar");
      }
      for (const item of items) {
        if (item.quantity <= 0) {
          throw new Error("Cantidad inválida");
        }
        if (!item.piece || !item.price) {
          throw new Error("Faltan campos por completar");
        }
      }

      const data = { name, client: client._id, pieces: [] };

      for (const item of items) {
        const pieceData = {
          quantity: item.quantity,
          piece: item.piece.value,
          price: +formatInt(item.price.slice(1)),
        };
        if (item.piece.new) {
          pieceData.new = true;
        }
        data.pieces.push(pieceData);
      }

      await backend.post("order", data);
      cancel();
    } catch (err) {
      console.log(err);
      setError(err?.message);
    }
    waitRef.current?.setLoading(false);
    setValidated(true);
  };

  const handleDetailsPressed = _id => {
    setPieceId(_id);
    ref.current?.show();
  };

  const handleClientChange = (value, action) => {
    setClient(value);
  };

  return (
    <WaitAsync func={getData} ref={waitRef}>
      <div className="fw-bold fs-1">Nueva Cotización</div>
      <Form
        noValidate
        validated={validated}
        onSubmit={e => {
          e.preventDefault();
          e.stopPropagation();
          submit();
        }}
      >
        <div className="m3-col">
          <Form.Group>
            <Form.Control
              onChange={e => setName(e.target.value)}
              value={name}
              placeholder="Nombre"
              required
            />
          </Form.Group>

          <Select
            styles={{
              option: styles => ({ ...styles, color: "black" }),
              control: styles => ({ ...styles, flexDirection: "row" }),
              input: styles => ({ ...styles, flexDirection: "row" }),
            }}
            isClearable
            onChange={handleClientChange}
            value={client}
            options={clients}
            placeholder="Cliente"
            classNamePrefix="react-select"
            className="flex-fill"
            required
          />

          <div
            className="grid"
            style={{
              gap: "16px 8px",
              gridTemplateColumns:
                "minmax(auto, 100px) minmax(200px, 1fr) auto auto auto",
            }}
          >
            {items.map((v, i) => (
              <Item
                key={i}
                index={i}
                item={v}
                setItems={setItems}
                pieces={pieces}
                setPieces={setPieces}
                onDetailsPressed={handleDetailsPressed}
              />
            ))}
          </div>
          <Button
            variant="primary"
            onClick={() => {
              setItems(p => [...p, getNewItem()]);
            }}
          >
            <div className="align-self-center">Agregar</div>
          </Button>
          <div className="fw-bold fs-3">Resumen</div>
          <div
            className="grid"
            style={{
              gap: "16px",
              gridTemplateColumns: "74px 1fr auto auto",
            }}
          >
            {items.map((v, i) => (
              <React.Fragment key={i}>
                {v.piece && (
                  <>
                    <div className="text-end">{v.quantity}</div>
                    <div>{v.piece.label}</div>
                    <div>$</div>
                    <div className="text-end">
                      {formatPrice(+formatInt(v.price) * v.quantity)}
                    </div>
                    <div
                      className="border-bottom"
                      style={{ gridColumn: "1 / 5", height: "0px" }}
                    />
                  </>
                )}
              </React.Fragment>
            ))}
          </div>
          <div className="text-end">{`Total $${formatPrice(
            items.reduce((p, v) => p + +formatInt(v.price) * v.quantity, 0)
          )}`}</div>

          {error && <div className="invalid-feedback text-center">{error}</div>}
          <div className="flex-row align-self-center">
            <Button variant="secondary" onClick={cancel}>
              Cancelar
            </Button>
            <Button variant="primary ms-3" onClick={submit}>
              Crear
            </Button>
          </div>
        </div>
      </Form>
      <PieceModal pieceId={pieceId} ref={ref} />
    </WaitAsync>
  );
};

const Item = ({
  index,
  item,
  setItems,
  pieces,
  setPieces,
  onDetailsPressed = _id => {},
}) => {
  const { quantity, piece, price } = item;

  const set = (k, i) => v => {
    setItems(p => {
      const n = [...p];
      n[i] = { ...n[i], [k]: v };
      return n;
    });
  };

  const setQuantity = set("quantity", index);
  const setPiece = set("piece", index);
  const setPrice = set("price", index);

  const remove = () => {
    setItems(p => {
      const n = [...p];
      n.splice(index, 1);
      return n;
    });
  };

  const handlePieceChange = (value, action) => {
    setPiece(value);
    setPrice(formatPrice(value?.price));
  };

  return (
    <>
      <Form.Group>
        <IntegerInput
          onChange={e => {
            e.target.scrollLeft = e.target.scrollWidth;
            let newQuantity = e.target.value;
            if (newQuantity === "0") {
              newQuantity = "1";
            }
            setQuantity(newQuantity || "1");
          }}
          onBlur={e => (e.target.scrollLeft = e.target.scrollWidth)}
          value={quantity}
          type="number"
          required
          style={{ textAlign: "right" }}
        />
      </Form.Group>
      <Select
        styles={{
          option: styles => ({ ...styles, color: "black" }),
          control: styles => ({ ...styles, flexDirection: "row" }),
          input: styles => ({ ...styles, flexDirection: "row" }),
        }}
        isClearable
        onChange={handlePieceChange}
        value={piece}
        options={pieces}
        placeholder="Pieza"
        formatCreateLabel={v => `Nueva Pieza "${v}"`}
        classNamePrefix="react-select"
        className="flex-fill"
      />

      <Button
        variant="primary align-self-center"
        onClick={() => onDetailsPressed(piece._id)}
        disabled={!piece}
      >{`$${formatPrice(piece?.price)}`}</Button>
      <Form.Group>
        <PriceInput onChange={setPrice} value={price} required />
      </Form.Group>
      <Button variant="secondary justify-content-center" onClick={remove}>
        <i className="bi bi-x-lg" />
      </Button>
    </>
  );
};

export default QuotationForm;
