import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Loading from "./Loading";

const ButtonAsync = ({
  onClick = async () => {},
  disabled,
  children,
  ...props
}) => {
  const [loading, setLoading] = useState(false);

  const handleClick = async () => {
    setLoading(true);
    await onClick();
    setLoading(false);
  };

  return (
    <Button onClick={handleClick} disabled={loading || disabled} {...props}>
      {loading ? <Loading small /> : children}
    </Button>
  );
};

export default ButtonAsync;
