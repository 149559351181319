import Button from "react-bootstrap/Button";
import React, { useRef, useState } from "react";
import LoadingWrapper from "./LoadingWrapper";

const FileInput = ({
  url,
  onRemove = () => {},
  onChange = e => {},
  children,
  ...props
}) => {
  const inputRef = useRef();
  const [uploading, setUploading] = useState(false);
  const handleChange = async e => {
    setUploading(true);
    await onChange(e);
    setUploading(false);
  };
  return (
    <>
      <div className="btn-group" style={{ minHeight: "38px" }}>
        <a
          href={url}
          target="_blank"
          rel="noreferrer"
          className="btn btn-primary d-flex m1-row justify-content-center flex-fill"
          disabled={uploading || !url}
        >
          <LoadingWrapper loading={uploading} small>
            {children}
          </LoadingWrapper>
        </a>
        <Button
          variant="primary flex-center"
          onClick={() => inputRef.current?.click()}
          disabled={uploading}
        >
          <LoadingWrapper loading={uploading} small>
            <i className="bi bi-upload" />
          </LoadingWrapper>
        </Button>
        <Button
          variant="primary flex-center"
          onClick={onRemove}
          disabled={uploading}
        >
          <LoadingWrapper loading={uploading} small>
            <i className="bi bi-trash" />
          </LoadingWrapper>
        </Button>
      </div>
      <input
        ref={inputRef}
        type="file"
        className="d-none"
        onChange={handleChange}
        {...props}
      />
    </>
  );
};

export default FileInput;
