import axios from "axios";
import React, { useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { domain } from "./constants";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";

const Password = () => {
  const { token } = useParams();
  const navigate = useNavigate();

  const [password, setPassword] = useState("");

  const [validated, setValidated] = useState(false);
  const [error, setError] = useState("");

  const [hasCapsLock, setHasCapsLock] = useState(false);

  const submit = async () => {
    try {
      if (!password) {
        setError("Faltan campos por completar");
        setValidated(true);
        return;
      }
      await axios.post(`${domain}/api/user/blank`, { token, password });
      navigate("/", { replace: true });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <div style={{ height: "20vh" }} />

      <Card className="align-self-center" style={{ minWidth: "350px" }}>
        <Card.Body>
          <div className="fw-bold text-center fs-3 mb-3">Nueva Contraseña</div>
          <Form
            noValidate
            validated={validated}
            onSubmit={e => {
              e.preventDefault();
              e.stopPropagation();
              submit();
            }}
          >
            {hasCapsLock && (
              <div className="invalid-feedback mt-0">
                Tienes las mayúsculas activas
              </div>
            )}
            <Form.Group>
              <Form.Control
                onChange={e => setPassword(e.target.value)}
                onKeyDown={e => {
                  setHasCapsLock(e.getModifierState("CapsLock"));
                }}
                value={password}
                placeholder="Contraseña"
                type="password"
                name="password"
                id="password"
                autoComplete="new-password"
                autoCapitalize="off"
                required
              />
            </Form.Group>

            {error && <div className="invalid-feedback">{error}</div>}

            <Button
              variant="primary"
              className="align-self-center mt-3"
              onClick={submit}
            >
              Acceder
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};

export default Password;
