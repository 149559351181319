import React, { useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import backend from "../../backend";
import CheckBack from "../../components/CheckBack";
import Modal from "../../components/Modal";
import WaitAsync from "../../components/WaitAsync";

import { Form } from "react-bootstrap";
import FileInput from "../../components/FileInput";

const OrderFiles = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const ref = useRef();
  const [order, setOrder] = useState();
  const [error, setError] = useState();
  const [fileError, setFileError] = useState();

  const [quotation, setQuotation] = useState("");
  const [purchaseOrder, setPurchaseOrder] = useState("");
  const [deliveryOrder, setDeliveryOrder] = useState("");
  const [bill, setBill] = useState("");
  const quotationRef = useRef();
  const purchaseOrderRef = useRef();
  const deliveryOrderRef = useRef();
  const billRef = useRef();

  const handleQuotationUpload = async e => {
    setFileError("");
    try {
      const newFile = e.target.files[0];
      if (newFile.name.split(".").slice(-1)[0] !== "pdf") {
        setFileError("Debes subir un pdf");
      }
      const formData = new FormData();
      formData.append("quotation", newFile);
      formData.append("_id", order._id);
      setQuotation((await backend.post("order/quotation", formData)).data);
    } catch (err) {
      console.log(err);
      setFileError(err.message);
    }
  };

  const handleQuotationRemove = async () => {
    try {
      setQuotation("");
      await backend.delete("order/quotation", { data: { _id: order._id } });
    } catch (err) {
      console.log(err);
    }
  };

  const handlePurchaseOrderUpload = async e => {
    setFileError("");
    try {
      const newFile = e.target.files[0];
      if (newFile.name.split(".").slice(-1)[0] !== "pdf") {
        setFileError("Debes subir un pdf");
      }
      const formData = new FormData();
      formData.append("purchase", newFile);
      formData.append("_id", order._id);
      setPurchaseOrder((await backend.post("order/purchase", formData)).data);
    } catch (err) {
      console.log(err);
      setFileError(err.message);
    }
  };

  const handlePurchaseOrderRemove = async () => {
    try {
      setPurchaseOrder("");
      await backend.delete("order/purchase", { data: { _id: order._id } });
    } catch (err) {
      console.log(err);
    }
  };

  const handleDeliveryOrderUpload = async e => {
    setFileError("");
    try {
      const newFile = e.target.files[0];
      if (newFile.name.split(".").slice(-1)[0] !== "pdf") {
        setFileError("Debes subir un pdf");
      }
      const formData = new FormData();
      formData.append("delivery", newFile);
      formData.append("_id", order._id);
      setDeliveryOrder((await backend.post("order/delivery", formData)).data);
    } catch (err) {
      console.log(err);
      setFileError(err.message);
    }
  };

  const handleDeliveryOrderRemove = async () => {
    try {
      setDeliveryOrder("");
      await backend.delete("order/delivery", { data: { _id: order._id } });
    } catch (err) {
      console.log(err);
    }
  };

  const handleBillUpload = async e => {
    setFileError("");
    try {
      const newFile = e.target.files[0];
      if (newFile.name.split(".").slice(-1)[0] !== "pdf") {
        setFileError("Debes subir un pdf");
      }
      const formData = new FormData();
      formData.append("bill", newFile);
      formData.append("_id", order._id);
      setBill((await backend.post("order/bill", formData)).data);
    } catch (err) {
      console.log(err);
      setFileError(err.message);
    }
  };

  const handleBillRemove = async () => {
    try {
      setBill("");
      await backend.delete("order/bill", { data: { _id: order._id } });
    } catch (err) {
      console.log(err);
    }
  };

  const getOrder = async () => {
    try {
      const order = (await backend.get(`order/${location.state?._id}`)).data;
      setQuotation(order.quotation);
      setPurchaseOrder(order.purchaseOrder);
      setDeliveryOrder(order.deliveryOrder);
      setBill(order.bill);
      setOrder(order);
    } catch (err) {
      console.log(err);
    }
  };

  const goBack = async () => {
    await new Promise(r => setTimeout(r, 100));
    navigate(-1);
  };

  return (
    <CheckBack to="/orders" onShow={() => ref.current?.show()}>
      <Modal
        ref={ref}
        title={order?.name || ""}
        hideCancel
        hideAccept
        onAccept
        onHide={goBack}
        loadOnAccept
      >
        <WaitAsync func={getOrder}>
          {order?.name && order?.number && (
            <p>
              {order?.number} {order?.name}
            </p>
          )}
          <div>Archivos:</div>
          <div className="m3-col">
            <FileInput
              url={quotation?.url}
              onChange={handleQuotationUpload}
              onRemove={handleQuotationRemove}
              accept=".pdf"
            >
              <i className="bi bi-file-pdf align-self-center" />
              <div className="fw-bold">Cotización</div>
            </FileInput>

            <FileInput
              url={purchaseOrder?.url}
              onChange={handlePurchaseOrderUpload}
              onRemove={handlePurchaseOrderRemove}
              accept=".pdf"
            >
              <i className="bi bi-file-pdf align-self-center" />
              <div className="fw-bold">Orden de Venta</div>
            </FileInput>

            <FileInput
              url={deliveryOrder?.url}
              onChange={handleDeliveryOrderUpload}
              onRemove={handleDeliveryOrderRemove}
              accept=".pdf"
            >
              <i className="bi bi-file-pdf align-self-center" />
              <div className="fw-bold">Orden de Envío</div>
            </FileInput>

            <FileInput
              url={bill?.url}
              onChange={handleBillUpload}
              onRemove={handleBillRemove}
              accept=".pdf"
            >
              <i className="bi bi-file-pdf align-self-center" />
              <div className="fw-bold">Boleta</div>
            </FileInput>
          </div>
          <Form.Control
            onChange={handleQuotationUpload}
            type="file"
            accept=".pdf"
            className="d-none"
            ref={quotationRef}
          />
          <Form.Control
            onChange={handlePurchaseOrderUpload}
            type="file"
            accept=".pdf"
            className="d-none"
            ref={purchaseOrderRef}
          />
          <Form.Control
            onChange={handleDeliveryOrderUpload}
            type="file"
            accept=".pdf"
            className="d-none"
            ref={deliveryOrderRef}
          />
          <Form.Control
            onChange={handleBillUpload}
            type="file"
            accept=".pdf"
            className="d-none"
            ref={billRef}
          />
        </WaitAsync>
      </Modal>
    </CheckBack>
  );
};

export default OrderFiles;
