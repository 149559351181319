import React, { useRef, useState } from "react";
import Form from "react-bootstrap/Form";
import backend from "../../backend";
import ModalForm from "../../components/ModalForm";
import CheckBack from "../../components/CheckBack";

import validator from "validator";
import { useLocation, useNavigate } from "react-router-dom";

const EntityForm = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const _id = location.state?._id;

  const formRef = useRef();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [rut, setRut] = useState("");
  const [type, setType] = useState("");

  // TODO: Disable edit of email and type

  const submit = async () => {
    if (!name || !email || !type) {
      throw new Error("Faltan campos por completar");
    }

    if (email && !validator.isEmail(email)) {
      throw new Error("Correo no válido");
    }

    if (_id) {
      const entity = (
        await backend.patch("entity", {
          _id,
          name,
          email,
          phone,
          rut,
          type,
        })
      ).data;
      navigate("/entities", { replace: true, state: { edit: entity } });
    } else {
      const entity = (
        await backend.post("entity", { name, email, phone, rut, type })
      ).data;

      navigate("/entities", { replace: true, state: { new: entity } });
    }
  };

  const resetForm = () => {
    setName("");
    setEmail("");
    setPhone("");
    setRut("");
    setType("");
  };

  const fillForm = async () => {
    try {
      const data = (await backend.get(`entity/${_id}`)).data;
      setName(data.name);
      setEmail(data.email);
      setPhone(data.phone);
      setRut(data.rut);
      setType(data.type);
    } catch (err) {
      console.log(err);
    }
  };

  const goBack = async () => {
    await new Promise(r => setTimeout(r, 100));
    navigate(-1);
  };

  return (
    <CheckBack to="/entities" onShow={() => formRef.current?.show()}>
      <ModalForm
        ref={formRef}
        title={_id ? "Editar Contacto" : "Nuevo Contacto"}
        submit={submit}
        onShow={() => (_id ? fillForm() : resetForm())}
        onHide={goBack}
      >
        <Form.Group>
          <Form.Control
            onChange={e => setName(e.target.value)}
            value={name}
            placeholder="Nombre"
            required
          />
        </Form.Group>
        <Form.Group className="mt-3">
          <Form.Control
            onChange={e => setEmail(e.target.value)}
            value={email}
            placeholder="Correo electrónico"
            type="email"
            required
            pattern="/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/"
          />
        </Form.Group>
        <Form.Group className="mt-3">
          <Form.Control
            onChange={e => setPhone(e.target.value)}
            value={phone}
            placeholder="Teléfono"
            type="tel"
          />
        </Form.Group>
        <Form.Group className="mt-3">
          <Form.Control
            onChange={e => setRut(e.target.value)}
            value={rut}
            placeholder="Rut"
          />
        </Form.Group>
        <Form.Group className="mt-3">
          <Form.Select
            onChange={e => setType(e.target.value)}
            value={type}
            placeholder="Tipo"
            required
          >
            <option value="" disabled>
              Tipo
            </option>
            <option value="client">Cliente</option>
            <option value="provider">Proveedor</option>
          </Form.Select>
        </Form.Group>
      </ModalForm>
    </CheckBack>
  );
};

export default EntityForm;
