import React, { useRef, useState } from "react";
import Form from "react-bootstrap/Form";

import backend from "../../backend";
import ModalForm from "../../components/ModalForm";
import CheckBack from "../../components/CheckBack";

import { useNavigate } from "react-router-dom";

const DriveForm = () => {
  const navigate = useNavigate();

  const formRef = useRef();

  const [drive, setDrive] = useState();

  const submit = async () => {
    try {
      await backend.patch("data/drive", { drive });
    } catch (err) {
      console.log(err);
    }
  };

  const getDrive = async () => {
    try {
      setDrive((await backend.get("data/drive")).data);
    } catch (err) {
      console.log(err);
    }
  };

  const goBack = async () => {
    await new Promise(r => setTimeout(r, 100));
    navigate(-1);
  };

  return (
    <CheckBack to="/settings" onShow={() => formRef.current?.show()}>
      <ModalForm
        ref={formRef}
        title={"Google Drive"}
        submit={submit}
        onShow={getDrive}
        onHide={goBack}
        size="xl"
      >
        {!drive && (
          <div
            className="m2-col p-4 mb-2"
            style={{ background: "rgba(0,0,0,0.2)" }}
          >
            <span>
              Necesitas crear una carpeta en google drive y compartirla a este
              correo con el permiso de <span className="fw-bold">Editor</span>
            </span>
            <div className="fw-bold flex-center">
              worker@shared-folder-manager.iam.gserviceaccount.com
            </div>
            <div>
              luego tienes que ir a la carpeta y mirar el url, será algo así
            </div>
            <div className="fw-bold flex-center">
              https://drive.google.com/drive/u/0/folders/un_ID_muy_largo
            </div>
            <span>
              tienes que copiar el <span className="fw-bold">ID</span> y pegarlo
              abajo
            </span>
          </div>
        )}
        <Form.Group>
          <Form.Label>ID Carpeta Compartida</Form.Label>
          <Form.Control
            onChange={e => setDrive(e.target.value)}
            value={drive}
          />
        </Form.Group>
      </ModalForm>
    </CheckBack>
  );
};

export default DriveForm;
