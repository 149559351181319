import React, { useImperativeHandle, useRef } from "react";
import Modal from "./Modal";

const ConfirmModal = React.forwardRef(
  (
    { title = "¿Estás seguro?", onAccept = async () => {}, children, ...props },
    ref
  ) => {
    useImperativeHandle(ref, () => ({
      waitConfirm,
    }));

    const confirmRef = useRef();

    const promiseResolve = useRef();
    const promiseReject = useRef();

    const waitConfirm = async () => {
      confirmRef.current?.show();
      await new Promise((resolve, reject) => {
        promiseResolve.current = resolve;
        promiseReject.current = reject;
      });
      await onAccept();
    };

    const handleConfirmAccept = () => {
      if (promiseResolve.current) {
        promiseResolve.current();
      }
    };

    const handleConfirmCancel = () => {
      if (promiseReject.current) {
        promiseReject.current();
      }
    };

    return (
      <Modal
        ref={confirmRef}
        title={title}
        onAccept={handleConfirmAccept}
        onCancel={handleConfirmCancel}
        {...props}
      >
        {children}
      </Modal>
    );
  }
);

export default ConfirmModal;
