import React, { useImperativeHandle, useRef, useState } from "react";
import backend from "../../backend";
import { formatDate } from "../../utils";

import Modal from "../../components/Modal";
import WaitAsync from "../../components/WaitAsync";
import PiecePrice from "../../components/PiecePrice";

const PieceModal = React.forwardRef(({ pieceId, ...props }, ref) => {
  useImperativeHandle(ref, () => ({
    show,
  }));

  const modalRef = useRef();

  const show = () => modalRef.current?.show();

  const [piece, setPiece] = useState();

  const getPiece = async () => {
    if (!pieceId) {
      return;
    }
    try {
      setPiece((await backend.get(`piece/${pieceId}`)).data);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Modal
      ref={modalRef}
      title={piece?.sku || ""}
      hideCancel
      outsideClose
      hideAccept
      {...props}
    >
      <WaitAsync func={getPiece}>
        <div className="m3-col">
          <div>
            {piece?.name && <div className="fw-bold">{piece.name}</div>}
            {piece?.details && <div>{piece.details}</div>}
            {piece?.minStock && (
              <div>{`Cantidad mínima: ${piece.minStock}`}</div>
            )}
          </div>
          <div>
            {(piece?.material || piece?.geometry) && (
              <div className="fw-bold">Material</div>
            )}
            {piece?.material && <div>{piece.material}</div>}
            {piece?.geometry && <div>{piece.geometry}</div>}
          </div>
          {piece?.blueprint && (
            <a
              href={piece.blueprint.url}
              target="_blank"
              rel="noreferrer"
              className="btn btn-primary d-flex flex-row justify-content-center"
            >
              <i className="bi bi-file-pdf align-self-center pe-1" />
              <div>Plano</div>
            </a>
          )}
          {/* <iframe
            src="https://drive.google.com/file/d/1U6WDIul5x-lF6zOf34gdt5dGSDkNqhB8/preview"
            width="640"
            height="480"
            allow="autoplay"
          ></iframe> */}

          {piece?.processes && <PiecePrice piece={piece} />}
          <div className="fst-italic text-muted">{`Última vez modificado: ${formatDate(
            piece?.updatedAt
          )}`}</div>
        </div>
      </WaitAsync>
    </Modal>
  );
});

export default PieceModal;
