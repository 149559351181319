import React, { useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import backend from "../../backend";
import CheckBack from "../../components/CheckBack";
import Modal from "../../components/Modal";
import WaitAsync from "../../components/WaitAsync";

const Machine = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const ref = useRef();
  const [machine, setMachine] = useState();

  const getMachine = async () => {
    try {
      setMachine((await backend.get(`machine/${location.state?._id}`)).data);
    } catch (err) {
      console.log(err);
    }
  };

  const getStatus = type => {
    switch (type) {
      case "inactive":
        return "Inactivo";
      case "active":
        return "Activo";
      case "archived":
        return "Archivado";
      default:
        return "";
    }
  };

  const goBack = async () => {
    await new Promise(r => setTimeout(r, 100));
    navigate(-1);
  };

  return (
    <CheckBack to="/machines" onShow={() => ref.current?.show()}>
      <Modal
        ref={ref}
        title={machine?.name || ""}
        hideCancel
        outsideClose
        onHide={goBack}
      >
        <WaitAsync func={getMachine}>
          {machine?.name && <p>{machine?.name}</p>}
          {machine?.category && <p>{machine?.category}</p>}
          {machine?.status && <p>{getStatus(machine?.status)}</p>}
        </WaitAsync>
      </Modal>
    </CheckBack>
  );
};

export default Machine;
