import React, { useImperativeHandle, useState } from "react";
import Button from "react-bootstrap/Button";
import Loading from "./Loading";

const LoadingOverlay = React.forwardRef((_props, ref) => {
  useImperativeHandle(ref, () => ({
    show,
    hide,
  }));
  const [visible, setVisible] = useState();

  const show = () => setVisible(true);
  const hide = () => setVisible();

  return (
    <>
      {visible && (
        <div
          style={{
            position: "fixed",
            background: "#0000007f",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 99,
            width: "100%",
            height: "100%",
          }}
        >
          <Loading />
        </div>
      )}
    </>
  );
});

export default LoadingOverlay;
