const domain =
  process.env.NODE_ENV === "production"
    ? "https://bini.elixslabs.cl"
    : process.env.REACT_APP_LOCAL_IP || "http://192.168.0.1:3011";

const secret =
  "e212cb6fb593d5c5531811d02f031ab86204c67e1e89af5f4667d181dbfdd6bd";

const DAYS = [
  "Domingo",
  "Lunes",
  "Martes",
  "Miércoles",
  "Jueves",
  "Viernes",
  "Sábado",
];

const DAYS_SHORT = ["Dom", "Lun", "Mar", "Mié", "Jue", "Vie", "Sáb"];

export { domain, secret, DAYS, DAYS_SHORT };
