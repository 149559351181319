import React, { useImperativeHandle, useRef, useState } from "react";
import Form from "react-bootstrap/Form";
import Loading from "./Loading";
import Modal from "./Modal";

const ModalForm = React.forwardRef(
  (
    {
      title = "Title",
      submit = async () => {},
      onShow = async () => {},
      onHide = () => {},
      children,
      size,
      ...props
    },
    ref
  ) => {
    useImperativeHandle(ref, () => ({
      show,
      validated,
    }));

    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);

    const modalRef = useRef();

    const show = async () => {
      modalRef.current?.show();
      setLoading(true);
      await onShow();
      setLoading(false);
      setError();
      setValidated(false);
    };

    const [validated, setValidated] = useState(false);

    const _submit = async () => {
      setError();
      setValidated(false);
      try {
        await submit();
      } catch (err) {
        setValidated(true);
        if (err) {
          setError(err.message);
        }
        throw new Error("ModalForm: submit failed");
      }
    };

    return (
      <Modal
        title={title}
        ref={modalRef}
        onAccept={_submit}
        onHide={onHide}
        loadOnAccept
        size={size}
        {...props}
      >
        {loading ? (
          <Loading />
        ) : (
          <Form
            noValidate
            validated={validated}
            onSubmit={e => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            {children}
            {error && (
              <div className="invalid-feedback flex-center">{error}</div>
            )}
          </Form>
        )}
      </Modal>
    );
  }
);

export default ModalForm;
